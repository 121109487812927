<template>
  <div v-if="modalStack.length > 0" class="common-modal">
    <div class="modal-overlay" @click="handleCloseModal" />
    <div class="modal-dialog-container" :style="{ 'max-width': modalWidth }">
      <div class="close-btn" v-if="showClose" @click="handleCloseModal" />
      <div class="modal-data">
        <component
          :is="currentModal.component"
          v-bind="currentModal.props"
          ref="componentRef"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * CommonModal is a reusable modal component that allows managing a stack of modals.
 *
 * This component provides the following features:
 * - Stack management: You can open multiple modals, and they will stack on top of each other.
 * - Customization: You can customize the width of the modal, and control whether a close button appears.
 * - Replace current modal: Replace the current modal instead of adding to the stack.
 * - Close all modals: You can close all open modals currently in the stack
 *
 * Props:
 * - defaultModalWidth: Sets the default width of the modal (default is "550px").
 * - defaultShowClose: Sets whether the close button is shown by default (default is true).
 *
 * Emitters:
 * - OPEN_MODAL: Opens a new modal. You can pass an object with the following parameters:
 *    - component: A component to be rendered inside the modal.
 *    - props: Props to be passed to the component.
 *    - showClose: Whether to show the close button on the modal. Overrides the defaultShowClose prop for this modal.
 *    - modalWidth: The width of the modal. Overrides the defaultModalWidth prop for this modal.
 *    - replaceCurrentModal: If set to true, the current modal (if any) is replaced by the new one, rather than adding the new one to the stack.
 *
 * - CLOSE_MODAL: Closes the topmost modal. You can pass an object with the following parameter:
 *    - closeAllModals: If set to true, all modals are closed, not just the topmost one.
 */

import { COMMON_MODAL_EMITTERS } from "@/helpers/const";
import eventBus from "../../helpers/event-bus";

const { OPEN_MODAL, CLOSE_MODAL } = COMMON_MODAL_EMITTERS;

const DEFAULT_MODAL_WIDTH = "550px";

export default {
  name: "CommonModal",
  data() {
    return {
      modalStack: [],
      showClose: true,
      modalWidth: DEFAULT_MODAL_WIDTH,
    };
  },
  computed: {
    currentModal() {
      return this.modalStack.length > 0
        ? this.modalStack[this.modalStack.length - 1]
        : null;
    },
  },
  created() {
    eventBus.$on(
      OPEN_MODAL,
      ({ component, props, showClose, modalWidth, replaceCurrentModal }) => {
        this.addModalOpenClassOnBody();
        this.modalWidth = modalWidth || this.modalWidth;
        this.showClose = showClose !== undefined ? showClose : this.showClose;

        if (replaceCurrentModal && this.modalStack.length > 0) {
          this.modalStack.pop();
        }

        this.modalStack.push({ component, props });
      }
    );

    eventBus.$on(CLOSE_MODAL, ({ closeAllModals = false, sourceHook } = {}) => {
      this.handleCloseModal(closeAllModals, sourceHook);
    });
  },
  methods: {
    resetModalProps() {
      this.modalWidth = "550px";
      this.showClose = true;
    },
    handleCloseModal(closeAllModals, sourceHook) {
      if (this.$refs?.componentRef?.postCloseModal) {
        this.$refs?.componentRef?.postCloseModal?.(sourceHook);
      }
      if (closeAllModals) {
        this.modalStack = [];
      } else if (this.modalStack.length > 0) {
        this.modalStack.pop();
      }

      if (this.modalStack.length === 0) {
        this.resetModalProps();
        this.removeModalOpenClassOnBody();
      }
    },
    addModalOpenClassOnBody() {
      document.body.classList.add("modal-open");
    },
    removeModalOpenClassOnBody() {
      document.body.classList.remove("modal-open");
    },
    cleanupEventBus() {
      eventBus.$off(OPEN_MODAL);
      eventBus.$off(CLOSE_MODAL);
    },
  },
  beforeDestroy() {
    this.cleanupEventBus();
  },
  deactivated() {
    this.cleanupEventBus();
  },
};
</script>

<style lang="scss" scoped>
.common-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;

  .modal-overlay {
    background: var(--modal-overlay-bgColor);
  }

  .close-btn {
    width: 20px;
    aspect-ratio: 1;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 1;

    &:before,
    &:after {
      content: "";
      background: var(--secondaryTextCol);
      height: 3px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .modal-dialog-container {
    background: var(--white);
    border-radius: 4px;
    max-height: 90vh;
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 5;

    .modal-data {
      padding: 16px;
    }
  }
}

@import "../../styles/variables";
@media screen and (max-width: $responsive-breakpoint) {
  .common-modal {
    .modal-dialog-container {
      // the !important is necessary here to overwrite the default / modal width supplied as a prop
      max-width: calc(min(90vw, 550px)) !important;
    }
  }
}
</style>
