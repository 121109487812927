<template>
  <div class="mail-links">
    <titan-button
      v-if="webmailURL"
      class="full flex-center"
      @click="openWebmail"
    >
      <div>
        <div>
          <img src="../../assets/globe.svg" alt="access-webmail" />
          <span>{{ $t("utility.accessWebmail") }}</span>
        </div>
        <span class="font-400 font-12px"
          >{{ $t("emailAccounts.createEmail.on") }} {{ webmailURL }}</span
        >
      </div>
    </titan-button>
    <titan-button
      class="full flex-center mt-24"
      @click="redirectToDownload"
      type="transparent"
    >
      <div>
        <div>
          <img src="../../assets/mobile.png" alt="access-webmail" />
          <span>{{ $t("utility.accessOnMobile") }}</span>
        </div>
        <span class="font-400 font-12px">{{
          $t("emailAccounts.createEmail.onAndroidAndIos")
        }}</span>
      </div>
    </titan-button>
  </div>
</template>

<script>
import * as helper from "../../helpers";
import viewTypes from "../../views";
export default {
  name: "MailLinks",
  props: {
    email: String,
    password: String,
    sourceHook: String,
    webmailURL: String,
  },
  data() {
    return {
      theme: document.documentElement.getAttribute("theme"),
    };
  },
  methods: {
    openInSameTab() {
      if (
        viewTypes[document.documentElement.getAttribute("view")][
          "openInternalURLSInSameTab"
        ]
      ) {
        return true;
      }
      return false;
    },
    redirectToDownload() {
      if (this.openInSameTab()) {
        console.log("Open ins Same window");
        this.$emit("closeModal");
        this.$router.push("/device-download");
      } else {
        window.open(`${window.location.origin}/device-download`, "_blank");
      }
    },
    openWebmail() {
      helper.openWebmail(this.email);
      var eventObj = {
        eventName: "access_webmail_hook_click",
        source_hook: this.sourceHook,
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(eventObj, this.email, "flock_account")
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.mail-links {
  margin-top: 33px;
  span {
    color: var(--linkBtnCol);
  }
  button:not(.transparent) {
    div {
      span {
        color: var(--tabBgCol);
      }
    }
  }
  img {
    padding-right: 4px;
  }
}
</style>
