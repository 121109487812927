import smartStorage from "./smart-storage";

export const COUNTRY = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: 'Cote D"Ivoire', code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU", isBlacklistedForPurchase: true },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  {
    name: "Iran, Islamic Republic Of",
    code: "IR",
    isBlacklistedForPurchase: true,
  },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE", isBlacklistedForPurchase: true },
  { name: "Kiribati", code: "KI" },
  {
    name: 'Korea, Democratic People"S Republic of',
    code: "KP",
    isBlacklistedForPurchase: true,
  },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kosovo", code: "XK" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: 'Lao People"S Democratic Republic', code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY", isBlacklistedForPurchase: true },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  // {"name": "United Kingdom(UK)", "code": "UK"},
  { name: "United States", code: "US" },
  // {"name": "USA", "code": "US"},
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

export const STATES_IN_US = [
  {
    name: "Alabama",
    code: "AL",
  },
  {
    name: "Alaska",
    code: "AK",
  },
  {
    name: "American Samoa",
    code: "AS",
  },
  {
    name: "Arizona",
    code: "AZ",
  },
  {
    name: "Arkansas",
    code: "AR",
  },
  {
    name: "California",
    code: "CA",
  },
  {
    name: "Colorado",
    code: "CO",
  },
  {
    name: "Connecticut",
    code: "CT",
  },
  {
    name: "Delaware",
    code: "DE",
  },
  {
    name: "District Of Columbia",
    code: "DC",
  },
  {
    name: "Federated States Of Micronesia",
    code: "FM",
  },
  {
    name: "Florida",
    code: "FL",
  },
  {
    name: "Georgia",
    code: "GA",
  },
  {
    name: "Guam",
    code: "GU",
  },
  {
    name: "Hawaii",
    code: "HI",
  },
  {
    name: "Idaho",
    code: "ID",
  },
  {
    name: "Illinois",
    code: "IL",
  },
  {
    name: "Indiana",
    code: "IN",
  },
  {
    name: "Iowa",
    code: "IA",
  },
  {
    name: "Kansas",
    code: "KS",
  },
  {
    name: "Kentucky",
    code: "KY",
  },
  {
    name: "Louisiana",
    code: "LA",
  },
  {
    name: "Maine",
    code: "ME",
  },
  {
    name: "Marshall Islands",
    code: "MH",
  },
  {
    name: "Maryland",
    code: "MD",
  },
  {
    name: "Massachusetts",
    code: "MA",
  },
  {
    name: "Michigan",
    code: "MI",
  },
  {
    name: "Minnesota",
    code: "MN",
  },
  {
    name: "Mississippi",
    code: "MS",
  },
  {
    name: "Missouri",
    code: "MO",
  },
  {
    name: "Montana",
    code: "MT",
  },
  {
    name: "Nebraska",
    code: "NE",
  },
  {
    name: "Nevada",
    code: "NV",
  },
  {
    name: "New Hampshire",
    code: "NH",
  },
  {
    name: "New Jersey",
    code: "NJ",
  },
  {
    name: "New Mexico",
    code: "NM",
  },
  {
    name: "New York",
    code: "NY",
  },
  {
    name: "North Carolina",
    code: "NC",
  },
  {
    name: "North Dakota",
    code: "ND",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
  },
  {
    name: "Ohio",
    code: "OH",
  },
  {
    name: "Oklahoma",
    code: "OK",
  },
  {
    name: "Oregon",
    code: "OR",
  },
  {
    name: "Palau",
    code: "PW",
  },
  {
    name: "Pennsylvania",
    code: "PA",
  },
  {
    name: "Puerto Rico",
    code: "PR",
  },
  {
    name: "Rhode Island",
    code: "RI",
  },
  {
    name: "South Carolina",
    code: "SC",
  },
  {
    name: "South Dakota",
    code: "SD",
  },
  {
    name: "Tennessee",
    code: "TN",
  },
  {
    name: "Texas",
    code: "TX",
  },
  {
    name: "Utah",
    code: "UT",
  },
  {
    name: "Vermont",
    code: "VT",
  },
  {
    name: "Virgin Islands",
    code: "VI",
  },
  {
    name: "Virginia",
    code: "VA",
  },
  {
    name: "Washington",
    code: "WA",
  },
  {
    name: "West Virginia",
    code: "WV",
  },
  {
    name: "Wisconsin",
    code: "WI",
  },
  {
    name: "Wyoming",
    code: "WY",
  },
];
export const REGX = {
  // https://www.regextester.com/19
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  number: /^[0-9]+$/,
  imap: /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/g,
  // ref - https://stackoverflow.com/a/38578855/6748242
  USPostalCode: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
  // ref - https://stackoverflow.com/questions/160550/zip-code-us-postal-code-validation
  UKVATNumber: /(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})/,
  // ref - https://stackoverflow.com/questions/33625770/check-vat-number-for-syntactical-correctness-with-regex-possible,
  password: /(?=.*[`!@#$%^&*()_ +\-=\[\]{};':"\\|,.<>\/?~])(?=.{8,100}).*$/,
  // At least 8 characters,
  // At max 100 characters,
  // At least special character
  // Alphanumeric
};

export const partnerWiseFontFamily = {
  hostinger: [
    "https://fonts.googleapis.com/css?family=DM Sans",
    "https://fonts.google.com/noto/specimen/Noto+Sans",
  ],
  namesilo: ["https://fonts.googleapis.com/css?family=Nunito Sans"],
  hostGator: ["https://fonts.googleapis.com/css?family=Roboto"],
  //wordpress: "https://fonts.googleapis.com/css?family=Georgia", // might need later
};

export const NEO_HOST_NAMES = [
  "admin.neo.space",
  "admin-staging.neo.space",
  "admin-preprod.neo.space",
  "localhost",
];

export const NEO_BUSINESS_DOMAINS = [
  "co.site",
  "copreprod.site",
  "costaging.site",
];

export const DOMAIN_PROVIDERS = [
  {
    name: "GoDaddy",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/36753035414937-Setup-A-record-and-CNAME-record-for-GoDaddy",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460705562393-Setup-Titan-MX-and-TXT-records-for-GoDaddy",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14463031953689-Domain-Ownership-verification-for-GoDaddy",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/360037338253-Setup-Titan-MX-and-TXT-records-for-GoDaddy",
    },
  },
  {
    name: "Squarespace",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37017796137881-Setup-A-record-and-CNAME-record-for-Squarespace",
    },
  },
  {
    name: "Hostinger",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/36980804701209-Setup-A-record-and-CNAME-record-for-Hostinger",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14008499839513-Setup-Neo-MX-and-TXT-records-for-Hostinger",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462884319385-Domain-Ownership-verification-for-Hostinger",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/900003527246-Setup-Titan-MX-and-TXT-records-for-Hostinger",
    },
  },
  {
    name: "Register.com",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37319196093849-Setup-A-record-and-CNAME-record-for-Register-com",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460861506201-Setup-Neo-MX-and-TXT-records-for-Register-com",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462606274841-Domain-Ownership-verification-for-Register-com",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/360036854334-Setup-Titan-MX-and-TXT-records-for-Register-com",
    },
  },
  {
    name: "WordPress.com",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37186946723481-Setup-A-record-and-CNAME-record-for-WordPress",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14244317638041-Setup-Neo-MX-and-TXT-records-for-WordPress-com",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462510963225-Domain-Ownership-verification-for-WordPress-com",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/900000572946-Setup-Titan-MX-and-TXT-records-for-WordPress-com",
    },
  },
  {
    name: "Google Domains",
    neo: {
      aAndCnameUrl: "",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460684965913-Setup-Titan-MX-and-TXT-records-for-Google-Domains",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14463077505305-Domain-Ownership-verification-for-Google-Domains",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/900000057383-Setup-Titan-MX-and-TXT-records-for-Google-Domains ",
    },
  },
  {
    name: "Name.com",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37080027705497-Setup-A-record-and-CNAME-record-for-Name-com",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14244847682969-Setup-Neo-MX-and-TXT-records-for-Name-com",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462852331289-Domain-Ownership-verification-for-Name-com",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/360037338733-Setup-Titan-MX-and-TXT-records-for-Name-com",
    },
  },
  {
    name: "Papaki",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37319257046041-Setup-A-record-and-CNAME-record-for-Papaki",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460845829785-Setup-Neo-MX-and-TXT-records-for-Papaki",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462601146649-Domain-Ownership-verification-for-Papaki",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/900001318906-Setup-Titan-MX-and-TXT-records-for-Papaki",
    },
  },
  {
    name: "Dreamhost",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37319295403033-Setup-A-record-and-CNAME-record-for-Dreamhost",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460640795289-Setup-Titan-MX-and-TXT-records-for-Dreamhost",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462994807961-Domain-Ownership-verification-for-Dreamhost",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/360036854434-Setup-Titan-MX-and-TXT-records-for-Dreamhost",
    },
  },
  {
    name: "HostPapa",
    neo: {
      aAndCnameUrl: "",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460778831257-Setup-Titan-MX-and-TXT-records-for-HostPapa",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462831906841-Domain-Ownership-verification-for-HostPapa",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/360037338433-Setup-Titan-MX-and-TXT-records-for-HostPapa",
    },
  },
  {
    name: "VIPControl",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37319334767001-Setup-A-record-and-CNAME-record-for-VIPControl",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14461388096409-Setup-Neo-MX-and-TXT-records-for-VIPControl",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462567549721-Domain-Ownership-verification-for-VIPControl",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/360036854554-Setup-Titan-MX-and-TXT-records-for-VIPControl",
    },
  },
  {
    name: "One.com",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37551117542425-Connect-One-com-domain-to-Neo-Site",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460806494233-Setup-Neo-MX-and-TXT-records-for-One-com",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462605531417-Domain-Ownership-verification-for-One-com",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/360037338533-Setup-Titan-MX-and-TXT-records-for-One-com",
    },
  },
  {
    name: "Alibaba Cloud",
    neo: {
      aAndCnameUrl: "",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460545291161-Setup-Titan-MX-and-TXT-records-for-Alibaba-Cloud",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14463091605401-Domain-Ownership-verification-for-Alibaba-Cloud",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/360037338653-Setup-Titan-MX-and-TXT-records-for-Alibaba-Cloud",
    },
  },
  {
    name: "HostGator LatAm",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37032175972121-Setup-A-record-and-CNAME-record-for-Hostgator",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14279024008345-Setup-Neo-MX-and-TXT-records-for-HostGator",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462641771673-Domain-Ownership-verification-for-HostGator",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/900005560306-Setup-Titan-MX-and-TXT-records-for-HostGator",
    },
  },
  {
    name: "NameCheap",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/37319361404185-Setup-A-record-and-CNAME-record-for-Namecheap",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460784448793-Setup-Neo-MX-and-TXT-records-for-Namecheap",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462797138841-Domain-Ownership-verification-for-Namecheap",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/360036854054-Setup-Titan-MX-and-TXT-records-for-Namecheap",
    },
  },
  {
    name: "Heart Internet",
    neo: {
      aAndCnameUrl: "",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14460739531033-Setup-Titan-MX-and-TXT-records-for-HeartInternet",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14463031543193-Domain-Ownership-verification-for-HeartInternet",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/900001918283-Setup-Titan-MX-and-TXT-records-for-HeartInternet",
    },
  },
  {
    name: "NameSilo",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/36916062595225-Setup-A-record-and-CNAME-record-for-NameSilo",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14245262897817-Setup-Neo-MX-and-TXT-records-for-NameSilo",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462645389337-Domain-Ownership-verification-for-NameSilo",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/900003527066-Setup-Titan-MX-and-TXT-records-for-NameSilo",
    },
  },
  {
    name: "TSO Host",
    neo: {
      aAndCnameUrl: "",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14461928990361-Setup-Neo-MX-and-TXT-records-for-TSO-Host",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462544555033-Domain-Ownership-verification-for-TSO-Host",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/900003517166-Setup-Titan-MX-and-TXT-records-for-TSO-Host",
    },
  },
  {
    name: "WIX",
    neo: {
      aAndCnameUrl:
        "https://support.neo.space/hc/en-us/articles/36908713521945-Setup-A-record-and-CNAME-record-for-WIX",
      mxAndTxtUrl:
        "https://support.neo.space/hc/en-us/articles/14461435041945-Setup-Neo-MX-and-TXT-records-for-WIX",
      domainOwnershipUrl:
        "https://support.neo.space/hc/en-us/articles/14462539098649-Domain-Ownership-verification-for-WIX",
    },
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/900000995046-Setup-Titan-MX-and-TXT-records-for-WIX",
    },
  },
  {
    name: "BigRock",
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/31284377478809-Setup-Titan-MX-and-TXT-records-for-BigRock",
    },
  },
  {
    name: "HostGator India",
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/31244085479193-Setup-Titan-MX-and-TXT-records-for-HostGator-India",
    },
  },
  {
    name: "Vodien",
    titan: {
      mxAndTxtUrl:
        "https://support.titan.email/hc/en-us/articles/28657019723673-Setup-Titan-MX-and-TXT-records-for-Vodien",
    },
  },
];

export const ZENDESK_KEY = "48551abe-eec0-4dda-94d2-f3a48a1e36cb";
export const CUSTOMER = "customer";
export const ORDER = "order";
export const BUNDLE = "bundle";
export const SITE_ORDER = "site_order";
export const DOMAIN_ORDER = "neo_domain_order";

// This is the order of priority of DNS Banners in BLL
export const DNS_BANNER_CODES = {
  PENDING_DOMAIN_OWNERSHIP_VERIFICATION:
    "pending_domain_ownership_verification",
  BAD_MX_PARTNER_NS: "bad_mx_partner_ns",
  BAD_MX_USER_NS: "bad_mx_partner_ns",
  BAD_SPF_USER_NS: "bad_spf_user_ns",
  BAD_DKIM: "bad_dkim",
  BAD_DOMAIN: "bad_domain",
  ALL_GOOD: "All_good",
};

export const DNS_ERROR_TYPES = {
  HOST_NOT_FOUND: "HostNotFound",
  DOMAIN_NOT_FOUND: "DomainNotFound",
  LOOKUP_TIMEOUT: "LookupTimeout",
};

export const ERROR_BANNER_TYPE = {
  ADDON_EXPIRED_ERROR: "addonExpiredError",
  PLAN_EXPIRED_ERROR: "planExpiredError",
  NEO_PLAN_EXPIRED_ERROR: "neoPlanExpiredError",
  SUBSCRIPTION_CANCELLED: "subscriptionCancelled",
  SUBSCRIPTION_EXPIRED_PAYMENT_FAILURE: "subscriptionExpiredPaymentFailure",
};

// To avoid Circular Dependency, we have rewritten the isNeoBusinessDomain function here
function isNeoBusinessDomain() {
  const isNeoDomain = NEO_BUSINESS_DOMAINS.some((neoDomain) => {
    const regex = new RegExp(`${neoDomain}`, "gi");
    return regex.test(smartStorage.getItem("domain"));
  });
  return isNeoDomain;
}

export const PLAN_FEATURE_MAP = {
  STORAGE: "storage",
  WEB_MAIL: "web_mail",
  MOBILE_APPS: "mobile_apps",
  CALENDAR: "calendar",
  CONTACT_GROUPS: "contact_groups",
  READ_RECEIPTS: "read_receipts",
  EMAIL_TEMPLATES: "email_templates",
  UNDO_SEND: "undo_send",
  SCHEDULE_SEND: "schedule_send",
  EMAIL_REMINDERS: "email_reminders",
  BLOCK_SENDERS: "block_senders",
  PRIORITY_INBOX: "priority_inbox",
  CUSTOM_HTML: "custom_html",
  TURBO_SEARCH: "turbo_search",
  MULTI_DEVICE_SUPPORT: "multi_device_support",
  SEND_AS: "send_as",
  MULTIPLE_EXTERNAL_FORWARDER: "multiple_external_forwarder",
  ADVANCE_ANTIVIRUS: "advance_antivirus",
  ADVANCED_ANTI_SPAM: "advanced_anti_spam",
  TWO_FACTOR_AUTH: "two_factor_auth",
  UNLIMITED_MAIL_FILTERS: "unlimited_mail_filters",
  VIDEO_CALLING: "video_calling",
  NEO_COMPANY_BUSINESS_DOMAIN: "neo_company_business_domain",
  NEO_ONE_PAGE_SITE: "neo_one_page_site",
  MAIL_SEND: "mail_send",
  ALLOW_SENDERS: "allow_senders",
  RULES_FORWARDING: "rules_forwarding",
  TITAN_AI: "titan_ai",
  SIGNATURE_BUILDER: "signature_builder",
  EMAIL_MARKETING: "email_marketing",

  // Neo Features
  NEO_DOMAIN: "neo_domain",
  CUSTOM_DOMAIN: "custom_domain",
  NEO_SITE: "neo_site",
  APPOINTMENT_BOOKING: "appointment_booking",

  SITE_TEMPLATES: "site_templates",
  SITE_FONTS: "site_fonts",
  SITE_COLORS: "site_colors",
  SITE_STOCK_IMAGES: "site_stock_images",
  SITE_GALLERY: "site_gallery",
  SITE_PRODUCTS: "site_products",
  SITE_SERVICES: "site_services",
  SITE_VIDEO: "site_video",
  SITE_TESTIMONIALS: "site_testimonials",
  SITE_ANALYTICS: "site_analytics",
  SITE_CONTACT_FORM: "site_contact_form",
  SITE_WHATSAPP: "site_whatsapp",
  SITE_CONTACT_INFO: "site_contact_info",
  SITE_REMOVE_NEO: "site_remove_neo",
  SITE_SUPPORT: "site_support",
  SITE_SITEMAP: "site_sitemap",
  SITE_AI_SITE: "site_ai_site",
  SITE_AI_TEXT: "site_ai_text",
  SITE_STORAGE: "site_storage",
  SITE_FREE_TRIAL_DAYS: "site_free_trial_days",
  SITE_DOMAIN: "site_domain",
  NEO_DOMAIN_PAID: "neo_domain_paid",
};

export const PLAN_FEATURE_LIST = [
  { type: PLAN_FEATURE_MAP.STORAGE },
  {
    type: PLAN_FEATURE_MAP.NEO_COMPANY_BUSINESS_DOMAIN,
    isClientSideFeature: isNeoBusinessDomain(),
    attrs: {
      text: process.env.VUE_APP_NEO_BUSINESS_DOMAIN,
    },
  },
  {
    type: PLAN_FEATURE_MAP.NEO_ONE_PAGE_SITE,
    isClientSideFeature: isNeoBusinessDomain(),
    attrs: {
      text: process.env.VUE_APP_NEO_BUSINESS_DOMAIN,
    },
  },
  { type: PLAN_FEATURE_MAP.CONTACT_GROUPS },
  { type: PLAN_FEATURE_MAP.READ_RECEIPTS },
  { type: PLAN_FEATURE_MAP.EMAIL_TEMPLATES },
  { type: PLAN_FEATURE_MAP.TITAN_AI },
  { type: PLAN_FEATURE_MAP.EMAIL_MARKETING },
  { type: PLAN_FEATURE_MAP.SIGNATURE_BUILDER },
  { type: PLAN_FEATURE_MAP.SCHEDULE_SEND },
  { type: PLAN_FEATURE_MAP.EMAIL_REMINDERS },
  { type: PLAN_FEATURE_MAP.PRIORITY_INBOX },
  { type: PLAN_FEATURE_MAP.CUSTOM_HTML },
  { type: PLAN_FEATURE_MAP.SEND_AS },
  { type: PLAN_FEATURE_MAP.TWO_FACTOR_AUTH },
  { type: PLAN_FEATURE_MAP.WEB_MAIL },
  { type: PLAN_FEATURE_MAP.MOBILE_APPS },
  { type: PLAN_FEATURE_MAP.CALENDAR },
  { type: PLAN_FEATURE_MAP.UNDO_SEND },
  { type: PLAN_FEATURE_MAP.BLOCK_SENDERS },
  { type: PLAN_FEATURE_MAP.TURBO_SEARCH },
  { type: PLAN_FEATURE_MAP.ADVANCE_ANTIVIRUS },
  { type: PLAN_FEATURE_MAP.ADVANCED_ANTI_SPAM },
  { type: PLAN_FEATURE_MAP.MAIL_SEND },
  { type: PLAN_FEATURE_MAP.ALLOW_SENDERS },
  { type: PLAN_FEATURE_MAP.RULES_FORWARDING },
  { type: PLAN_FEATURE_MAP.UNLIMITED_MAIL_FILTERS },
  { type: PLAN_FEATURE_MAP.CONTACT_GROUPS },
  { type: PLAN_FEATURE_MAP.VIDEO_CALLING },
  { type: PLAN_FEATURE_MAP.MULTI_DEVICE_SUPPORT },
  { type: PLAN_FEATURE_MAP.MULTIPLE_EXTERNAL_FORWARDER },
];

export const DOMAIN_RECORD_TYPES = {
  DOMAIN_OWNERSHIP: "domainOwnership",
  MX_AND_TXT: "mxAndTxt",
  MX: "mx",
  SPF: "spf",
  DKIM: "dkim",
  A_AND_CNAME: "aAndCname",
  A: "a",
  CNAME: "cname",
};
export const SPECIAL_CHARACTERS = "!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~ ";

export const NUMERIC = Array.from(Array(10).keys()).join("");

export const SMALL_LETTERS = "abcdefghijklmnopqrstuvwxyz";

export const CAPITAL_LETTERS = SMALL_LETTERS.toUpperCase();

export const PASSWORD_SET =
  SPECIAL_CHARACTERS + NUMERIC + CAPITAL_LETTERS + SMALL_LETTERS;

export const AUTOGENERATED_PASSWORD_LENGTH = 15;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 100;

export const AUTO_RENEWAL_ACTIONS = {
  CARD_REMOVED: "Card removed",
  CARD_ADDED: "Card added",
  MANUAL: "Manual",
};

/** Events fired on the event bus to open and close the common modal **/
export const COMMON_MODAL_EMITTERS = {
  OPEN_MODAL: "show-modal",
  CLOSE_MODAL: "hide-modal",
};

/**
 * Object of webhook IDs of the Flock channels to which we need to post data
 * Each feature will have its own key having the IDs for the 3 environments
 * Please ensure that these channels are public channels and add the channel sharing link here so that we can access the channel directly from here
 **/
export const WEBHOOK_CHANNEL_IDS = {
  DOMAIN_CHANGE: {
    // staging & dev: https://web.flock.com/channel/?chat_jid=70f97304e96c478580c34ae0cfaa0e2a@groups.go.to&teamId=10
    // prod: https://web.flock.com/channel/?chat_jid=b92a77defaee4456adb4a79c2beec81d@groups.go.to&teamId=10
    stage: "67e87f35-f293-44f4-8c90-affe09ae7c17",
    dev: "67e87f35-f293-44f4-8c90-affe09ae7c17",
    prod: "b0779947-8db3-488f-985d-7fb8701c475f",
  },
  ORDER_CANCELLATION: {
    // staging & dev: https://web.flock.com/channel/?chat_jid=ecd0cb11d53e46628841d511a8bcf291@groups.go.to&teamId=10
    // prod: https://web.flock.com/channel/?chat_jid=02b122c234624dcaaad159420d51fa1c@groups.go.to&teamId=10
    stage: "8e6c7217-9a83-481c-b0b4-d78f9524827d",
    dev: "8e6c7217-9a83-481c-b0b4-d78f9524827d",
    prod: "ab45661e-2ecb-432a-982d-9dc0baaaac66",
  },
};

export const ADMIN_ROLE = {
  ACCOUNT_ADMIN: "accountAdmin", //Domain Admin
  DOMAIN_ADMIN: "domainAdmin", //Customer Admin
  PARTNER_SUPPORT: "partnerSupport", //Support person from Partner
  TITAN_SUPPORT: "titanSupport", //Support person from Titan
  TITAN: "titan", //Support person from Titan
};

export const ADMIN_TYPE = {
  CUSTOMER: "customer",
  ACCOUNT: "account",
};

export const SUBSCRIPTION_KEYS = {
  ACTIVE: "ACTIVE",
  REACTIVATE_SUBSCRIPTION: "REACTIVATE_SUBSCRIPTION",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  EXPIRY_EXTENSION: "EXPIRY_EXTENSION",
};

export const DOMAIN_STATUS = {
  ACTIVE: "active",
  SUSPENDED: "suspended",
};

export const ORDER_STATUS = {
  ACTIVE: "active",
  SUSPENDED: "suspended",
};

export const NEO_OFFERING = {
  COSITE: "co.site",
  CUSTOM_DOMAIN: "User custom domain",
};

export const CP_SOURCES_MAP = {
  IOS: "ios",
  ANDROID: "android",
};

export const BILLING_FREQUENCY = {
  YEARLY: "yearly",
  TWO_YEARLY: "two_yearly",
  FOUR_YEARLY: "four_yearly",
  MONTHLY: "monthly",
  QUARTERLY: "quarterly",
  SEMESTERLY: "semesterly",
};

export const BILLING_MONTHS = {
  monthly: 1,
  quarterly: 3,
  semesterly: 6,
  yearly: 12,
  two_yearly: 24,
  four_yearly: 48,
};

export const PLAN_BILLING_TRANSLATION_KEY_MAP = {
  yearly: "pricePerYear",
  two_yearly: "pricePerTwoYear",
  four_yearly: "pricePerFourYear",
  quarterly: "pricePerQuarter",
  semesterly: "pricePerSemester",
};

export const DOMAIN_BILLING_TRANSLATION_KEY_MAP = {
  yearly: "domainPricePerYear",
  two_yearly: "domainPricePerTwoYear",
  four_yearly: "domainPricePerFourYear",
  quarterly: "domainPricePerQuarter",
  semesterly: "domainPricePerSemester",
};

export const BILLING_PRICE = {
  FIRST_BILLING_CYCLE_PRICE: "firstBillingCyclePrice",
  PRICE: "price",
};

export const BILLING_CYCLE_SOURCES = {
  CHANGE_BILLING_CYCLE: "Change Billing Cycle",
  BILLING_AND_SUBSCRIPTION: "Billing And Subscription",
  MANUAL_RENEW: "Manual Renew",
  GET_EMAIL: "Get mail",
  GET_SITE: "Get site",
};

export const BILLING_CYCLE_SOURCES_LIST = [
  BILLING_CYCLE_SOURCES.CHANGE_BILLING_CYCLE,
  BILLING_CYCLE_SOURCES.BILLING_AND_SUBSCRIPTION,
  BILLING_CYCLE_SOURCES.MANUAL_RENEW,
  BILLING_CYCLE_SOURCES.GET_EMAIL,
  BILLING_CYCLE_SOURCES.GET_SITE,
];

export const ROUTE_ACTIONS = {
  "LAUNCH-EMAIL-CREATION": "launch-email-creation",
  LAUNCH_EMAIL_CREATION: "launchEmailCreation",
  RENEW: "renew",
  LAUNCH_HIGHER_PLAN_UPGRADE: "launchHigherPlanUpgrade",
  REACTIVATE_SUBSCRIPTION: "reactivateSubscription",
  SHOW_PLAN_FEATURE: "showPlanFeature",
  RENEW_CARD_PAYMENT: "renewCardPayment",
  GET_EMAIL: "getEmail",
  GET_SITE: "getSite",
};

export const ROUTE_ACTIONS_PROPS = {
  FEATURE_KEY: "featureKey",
};

export const AUTH_STATE = {
  NO_AUTH: "no_auth",
  AUTH: "auth",
};

export const EMAIL_VALIDITY_ERROR_CODES = {
  TOP_BRAND: "TOP_BRAND",
  NETWORK_ERROR: "NETWORK_ERROR",
  DEFAULT: "DEFAULT",
};

export const CUSTOM_ERROR_CODES = {
  MISSING_JWT: "MISSING_JWT",
};

export const FLOW_TYPES = {
  UPGRADE_PLAN: "upgradePlanFromBilling",
  SITE_UPGRADE_PLAN: "siteUpgradePlanFromBilling",
  RENEW_BUNDLE: "renewNow",
  RENEW_PRODUCT: "renewProduct",
  BUY_MAILBOXES: "buyMailboxes",
  CHANGE_BILLING_CYCLE: "changeBillingCycle",
  RENEW_CARD_PAYMENT: "renewCardPayment",
  MAIL_PROVISION_SINGLE_MAILBOX: "mailProvisionSingleMailbox",
  MAIL_PROVISION_MULTIPLE_MAILBOX: "mailProvisionMultipleMailbox",
  SITE_PROVISION: "siteProvision",
};

export const ENTRI_SETUP_MODE = {
  SITE: "site",
  EMAIL: "email",
};

export const GET_MAIL_FORM_DATA_CACHE_KEY = "getMailFormData";

export const DOWNGRADE_PLAN_FORM_LINK = "https://ask.neo.space/downgrade";

export const GET_NEO_MAIL_SOURCES = {
  MAIL: "site_app_switcher_mail",
  CALENDAR: "site_app_switcher_calendar",
  CONTACTS: "site_app_switcher_contacts",
  BOOKINGS: "site_app_switcher_bookings",
};

export const SUPPORT_ARTICLES = {
  COUNTRY_NOT_SUPPORTED:
    "https://support.neo.space/hc/en-us/articles/14463849280281-Countries-or-regions-where-Neo-is-not-supported",
};
