import Vue from "vue";
import Router from "vue-router";
// Initial chunk consisting of pre auth components and first page after authentication
import EmailAccounts from "@/components/EmailAccounts/EmailAccounts";
import PageNotFound from "@/components/Misc/PageNotFound";
import * as helpers from "./helpers";
import ControlPanelWidget from "@/components/cpWidget/cpWidget.vue";
import LoginWithToken from "@/components/Auth/LoginWithToken.vue";
import LoginWithJWT from "@/components/Auth/LoginWithJWT.vue";
import LoginFromWebmail from "@/components/Auth/LoginFromWebmail.vue";
import ResetPassword from "@/components/Auth/ResetPassword.vue";
import Login from "@/components/Auth/Login.vue";
import LoginFailureScreen from "@/components/Utility/LoginFailureScreen.vue";
import PurchaseFlow from "@/components/PurchaseFlow/PurchaseFlow.vue";
import MobileHome from "@/components/MobileHome/MobileHome.vue";
import { markPagePerformance } from "./analytics/performance/pageTimings";
import { PRODUCTS } from "@/helpers/product";
import eventBus from "./helpers/event-bus";

const shouldShowMobileView = () =>
  helpers.isMobile() && !helpers.isAppLoadedInsideIframe();

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

const showMobileView = shouldShowMobileView();

// Dynamic chunks
const BillingAndSubscription = () =>
  import(
    /* webpackChunkName: "billing-and-subscription" */ "@/components/BillingAndSubscription"
  );
const ManageSubscriptions = () =>
  import(
    /* webpackChunkName: "manage-subscriptions" */ "@/components/ManageSubscriptions"
  );
const ManageSite = () =>
  import(
    /* webpackChunkName: "manage-site" */ "@/components/ManageSite/ManageSite.vue"
  );

const ManageDNS = () =>
  import(
    /* webpackChunkName: "manage-dns" */ "@/components/ManageDNS/ManageDNS.vue"
  );

const AddonPage = () =>
  import(/* webpackChunkName: "upgrade" */ "@/components/AddonPage");

const MailBillingAndUpgrade = () =>
  import(
    /* webpackChunkName: "mail-upgrade" */ "@/components/BillingAndUpgrade/MailBillingAndUpgrade.vue"
  );

const SiteBillingAndUpgrade = () =>
  import(
    /* webpackChunkName: "site-upgrade" */ "@/components/BillingAndUpgrade/SiteBillingAndUpgrade.vue"
  );

const BuyEmailAccount = () =>
  import(
    /* webpackChunkName: "buy-email-accounts" */ "@/components/BuyEmailAccount/BuyEmailAccount.vue"
  );
const EmailForwarder = () =>
  import(
    /* webpackChunkName: "email-forwarder" */ "@/components/EmailForwarder/EmailForwarder.vue"
  );
const CatchAllEmail = () =>
  import(
    /* webpackChunkName: "catch-all-email" */ "@/components/CatchAllEmail/CatchAllEmail.vue"
  );
const DeviceDownload = () =>
  import(
    /* webpackChunkName: "device-download" */ "@/components/DeviceDownload/DeviceDownload.vue"
  );
const ConfigureDesktop = () =>
  import(
    /* webpackChunkName: "configure-desktop" */ "./components/configureDesktop/ConfigureDesktop.vue"
  );
const GetMail = () =>
  import(/* webpackChunkName: "get-mail" */ "./components/GetMail/GetMail.vue");
const PostPayment = () =>
  import(
    /* webpackChunkName: "post-payment" */ "./components/Misc/PostPayment.vue"
  );
const GetFlockMail = () =>
  import(
    /* webpackChunkName: "get-flockmail" */ "./components/Misc/GetFlockMail.vue"
  );
const DomainActivation = () =>
  import(
    /* webpackChunkName: "domain-activation" */ "./components/DomainActivation/DomainActivation.vue"
  );
const ConnectSiteDomain = () =>
  import(
    /* webpackChunkName: "connect-site-domain" */ "./components/ConnectSiteDomain/ConnectSiteDomain.vue"
  );
const EmailReputation = () =>
  import(
    /* webpackChunkName: "domain-activation" */ "./components/EmailReputation/EmailReputation.vue"
  );
const ImportEmail = () =>
  import(
    /* webpackChunkName: "import-email" */ "./components/ImportEmail/ImportEmail.vue"
  );
const PartnerProvision = () =>
  import(
    /* webpackChunkName: "partner-provision" */ "./components/PartnerProvision/PartnerProvision.vue"
  );

const RedirectHandler = () =>
  import("@/components/Utility/RedirectHandler.vue");

Vue.use(Router);

let router = new Router({
  mode: process.env.VUE_APP_ENV_FLAG === "beta" ? "hash" : "history",
  base: process.env.VUE_APP_BASE_PATH || "/",
  routes: [
    // Note: Make sure route name follows all lowercase and - separated format
    // eg: some-good-name

    // all login / auto login routes
    {
      path: "/",
      name: "Login",
      component: Login,
      alias: "/login",
      meta: {
        requiresAuth: false,
        mobileSupported: true,
      },
    },
    {
      path: "/mobile-home",
      name: "Mobile Home",
      component: MobileHome,
      alias: "/mobile-home",
      meta: {
        requiresAuth: true,
        view: "mobile",
        mobileSupported: true,
      },
    },
    {
      path: "/auto-login-error",
      name: "LoginFailureScreen",
      component: LoginFailureScreen,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/partner/purchase",
      name: "PurchaseFlow",
      component: PurchaseFlow,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
      },
    },
    {
      path: "/partner/provision",
      name: "PartnerProvision",
      component: PartnerProvision,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
      },
    },
    {
      path: "/partner/cpWidget",
      name: "cpWidget" /* Iframe Mode */,
      component: LoginWithJWT,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
        view: "widget",
        homeRoute: "/home",
        initialiseTheme: true,
      },
    },
    {
      path: "/home",
      name: "ControlPanelWidget",
      component: ControlPanelWidget,
      meta: {
        requiresAuth: true,
        isFreshAuth: true,
        view: "widget",
        altName: "HOME",
      },
      props: true,
    },
    /**
     * Supported auto login flows
     * https://titanwiki.atlassian.net/wiki/spaces/TE/pages/518488117/CP+-+Auto+login+to+Control+Panel
     */
    {
      path: "/autoLogin",
      name: "LoginWithToken",
      component: LoginWithToken,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
        mobileSupported: true,
      },
    },
    {
      path: "/loginWithToken",
      name: "LoginWithJWT",
      component: LoginWithJWT,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
        mobileSupported: true,
      },
    },
    {
      path: "/partner/autoLogin",
      name: "autoLogin",
      component: LoginWithJWT,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
        mobileSupported: true,
      },
    },
    {
      path: "/webmail/autoLogin",
      name: "webmailAutoLogin",
      component: LoginFromWebmail,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
        mobileSupported: true,
      },
    },

    // set and reset password
    {
      path: "/reset-password",
      name: "ResetPassword",
      alias: "/resetPassword",
      component: ResetPassword,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
        mobileSupported: true,
        altName: "RESET_PASSWORD",
      },
    },
    {
      path: "/set-password",
      name: "SetPassword",
      alias: "/setPassword",
      component: ResetPassword,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
        mobileSupported: true,
        altName: "RESET_PASSWORD",
      },
    },

    // routes after login
    {
      path: "/email-accounts",
      name: "EmailAccounts",
      alias: "/email_accounts",
      component: EmailAccounts,
      meta: {
        requiresAuth: true,
        altName: "EMAIL_ACCOUNTS",
        products: [PRODUCTS.MAIL_SUITE],
      },
      props: true,
    },
    {
      path: "/internal-forward",
      name: "EmailForwarder",
      component: EmailForwarder,
      meta: {
        requiresAuth: true,
        altName: "EMAIL_FORWARDER",
        products: [PRODUCTS.MAIL_SUITE],
      },
    },
    {
      path: "/catch-all-email",
      name: "CatchAllEmail",
      alias: "/catch_all_email",
      component: CatchAllEmail,
      meta: {
        requiresAuth: true,
        altName: "CATCH_ALL_EMAIL",
        products: [PRODUCTS.MAIL_SUITE],
      },
    },
    {
      path: "/device-download",
      name: "DeviceDownload",
      alias: "/device_download",
      component: DeviceDownload,
      meta: {
        requiresAuth: true,
        altName: "DEVICE_DOWNLOAD",
        products: [PRODUCTS.MAIL_SUITE],
      },
    },
    {
      path: "/configure-desktop",
      name: "ConfigureDesktop",
      alias: "/configure_desktop",
      component: ConfigureDesktop,
      meta: {
        requiresAuth: true,
        altName: "CONFIGURE_DESKTOP",
        products: [PRODUCTS.MAIL_SUITE],
      },
    },
    {
      path: "/get-mail",
      name: "GetMail",
      alias: "/get-mail",
      component: GetMail,
      meta: {
        requiresAuth: true,
        altName: "GET_MAIL",
        mobileSupported: true,
        products: [PRODUCTS.MAIL_SUITE, PRODUCTS.SITE],
      },
    },
    {
      path: "/manage-site",
      name: "ManageSite",
      component: ManageSite,
      meta: {
        requiresAuth: true,
        altName: "MANAGE_SITE",
      },
    },
    {
      path: "/manage-dns",
      name: "ManageDNS",
      component: ManageDNS,
      meta: {
        requiresAuth: true,
        altName: "MANAGE_DNS",
        products: [PRODUCTS.SITE],
      },
    },
    {
      // duplicate of `domain-verification`
      // keeping this for now to give backward compatibility
      path: "/activate",
      name: "DomainActivation",
      component: DomainActivation,
      meta: {
        requiresAuth: true,
        altName: "DOMAIN_ACTIVATION",
        products: [PRODUCTS.MAIL_SUITE],
      },
      props: true,
    },
    {
      path: "/domain-verification",
      name: "DomainVerification",
      component: DomainActivation,
      meta: {
        requiresAuth: true,
        altName: "DOMAIN_ACTIVATION",
        products: [PRODUCTS.MAIL_SUITE],
      },
      props: true,
    },
    {
      path: "/connect-site-domain",
      name: "ConnectSiteDomain",
      component: ConnectSiteDomain,
      meta: {
        requiresAuth: true,
        altName: "CONNECT_SITE_DOMAIN",
        products: [PRODUCTS.SITE],
      },
      props: true,
    },
    {
      path: "/email-reputation",
      name: "EmailReputation",
      component: EmailReputation,
      meta: {
        requiresAuth: true,
        altName: "EMAIL_REPUTATION",
        products: [PRODUCTS.MAIL_SUITE],
      },
      props: true,
    },
    {
      path: "/import-email",
      name: "ImportEmail",
      component: ImportEmail,
      meta: {
        requiresAuth: true,
        altName: "IMPORT_EMAIL",
        products: [PRODUCTS.MAIL_SUITE],
      },
    },
    {
      path: "/addons/:addon",
      name: "AddonPage",
      component: AddonPage,
      meta: {
        requiresAuth: true,
        products: [PRODUCTS.MAIL_SUITE],
      },
      props: true,
    },
    {
      path: "/billing-and-upgrade/:addon?",
      name: "MailBillingAndUpgrade",
      component: MailBillingAndUpgrade,
      meta: {
        altName: "MAIL_BILLING_AND_UPGRADE",
        requiresAuth: true,
        view: showMobileView ? "mobile" : "default",
        mobileSupported: true,
      },
      props: true,
    },
    {
      path: "/site-billing-and-upgrade/:addon?",
      name: "SiteBillingAndUpgrade",
      component: SiteBillingAndUpgrade,
      meta: {
        altName: "SITE_BILLING_AND_UPGRADE",
        requiresAuth: true,
        view: showMobileView ? "mobile" : "default",
        mobileSupported: true,
      },
      props: true,
    },
    {
      path: "/buy-email-account",
      name: "BuyEmailAccount",
      component: BuyEmailAccount,
      meta: {
        altName: "BUY_EMAIL_ACCOUNT",
        requiresAuth: true,
        view: showMobileView ? "mobile" : "default",
        mobileSupported: true,
        products: [PRODUCTS.MAIL_SUITE],
      },
      props: true,
    },
    {
      path: "/billing-and-subscription",
      name: "BillingAndSubscription",
      component: BillingAndSubscription,
      meta: {
        altName: "BILLING_AND_SUBSCRIPTION",
        requiresAuth: true,
        view: showMobileView ? "mobile" : "default",
        mobileSupported: false,
      },
      props: true,
    },
    {
      path: "/manage-subscriptions/:addon",
      name: "ManageSubscriptions",
      component: ManageSubscriptions,
      meta: {
        requiresAuth: true,
      },
      props: true,
    },

    // misc routes
    {
      path: "/getFlockMail",
      name: "getFlockMail",
      component: GetFlockMail,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
        altName: "GET_TITAN",
      },
    },
    {
      path: "/getTitan",
      name: "getTitanMail",
      component: GetFlockMail,
      meta: {
        requiresAuth: false,
        isFreshAuth: true,
        altName: "GET_TITAN",
      },
    },
    {
      path: "/postPayment",
      name: "PostPayment",
      component: PostPayment,
      meta: {
        requiresAuth: true,
        altName: "POST_PAYMENT",
      },
    },
    {
      path: "/redirect-handler",
      name: "Redirect",
      component: RedirectHandler,
      meta: {
        requiresAuth: true,
        altName: "REDIRECT",
        mobileSupported: false,
      },
    },

    // 404
    {
      path: "*",
      name: "Page not found",
      component: PageNotFound,
      meta: {
        requiresAuth: false,
        altName: "PAGE_NOT_FOUND",
      },
    },
  ],
});
export function interceptor(to, from, next) {
  markPagePerformance("mount", to.path);
  // TODO: Use of shouldShowMobileView function is for test purpose , Need to be removed and tests should be updated
  const showMobileView = shouldShowMobileView();
  if (to.meta.altName && helpers.isAppLoadedInsideIframe()) {
    helpers.sendPostMessageToParentWindow(helpers.PAGE_DID_LOAD, {
      pageName: to.meta.altName,
    });
  }

  if (showMobileView) {
    if (!to.meta.mobileSupported) {
      markPagePerformance("mount", "/mobile-home");
      next({
        path: "/mobile-home",
      });
      return;
    }
  }

  if (!showMobileView && to.path === "/mobile-home") {
    next({
      path: "/redirect-handler",
    });
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (smartStorage.getItem("authToken") == null) {
      markPagePerformance("mount", to.path);
      return eventBus.$emit("go-to-login");
    } else {
      markPagePerformance("mount", to.path);
      return next();
    }
  } else {
    if (smartStorage.getItem("authToken") !== null) {
      if (to.meta.isFreshAuth) {
        // avoid clearing source from session storage
        helpers.clearAuthSession(["source"]);
        markPagePerformance("mount", to.path);
        next();
        return;
      }
      next({
        path: "/redirect-handler",
        params: { nextUrl: to.fullPath },
      });
    } else {
      markPagePerformance("mount", to.path);
      next();
    }
  }
}
router.beforeEach(interceptor);

export default router;
