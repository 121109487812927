<template>
  <div class="country-not-supported-modal">
    <div class="success-icon">
      <img
        src="@/assets/browser-window-with-warning-icon.svg"
        alt="Country not supported icon"
      />
    </div>
    <div class="heading">{{ $t("countryNotSupportedModal.heading") }}</div>
    <div
      class="subHeading"
      v-html="
        $t('countryNotSupportedModal.subHeading', {
          kbArticleLink: kbArticleLink,
        })
      "
    />

    <titan-button class="confirmation" @click="closeModal">
      {{ $t("genericText.gotIt") }}
    </titan-button>
  </div>
</template>
<script>
import TitanButton from "@/components/core/TitanButton.vue";
import { COMMON_MODAL_EMITTERS, SUPPORT_ARTICLES } from "@/helpers/const";
import eventBus from "@/helpers/event-bus";
import bundleEvents from "@/telemetry/bundleEvents";
import { mapGetters } from "vuex";

export default {
  name: "CountryNotSupportedModal",
  components: { TitanButton },
  computed: {
    kbArticleLink() {
      return SUPPORT_ARTICLES.COUNTRY_NOT_SUPPORTED;
    },
    ...mapGetters(["purchaseAndBillingDetails"]),
  },
  mounted() {
    this.logRestrictedRegionMedusa();
  },
  methods: {
    logRestrictedRegionMedusa() {
      bundleEvents.logRestrictedRegionShown({
        billing_country: this.purchaseAndBillingDetails?.billing_country,
      });
    },
    closeModal() {
      eventBus.$emit(COMMON_MODAL_EMITTERS.CLOSE_MODAL, {
        closeAllModals: true,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.country-not-supported-modal {
  padding: 36px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .heading {
    font-size: 20px;
    font-weight: 600;
  }

  .confirmation {
    padding: 15px 20px;
    margin-top: 14px;
  }
}
</style>
