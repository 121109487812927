var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app","data-theme":_vm.theme}},[(!_vm.shouldShowResponsiveLayout)?_c('mobile-screen'):_c('div',[(_vm.isOfacFlag)?_c('div',[_c('Ofac',{attrs:{"ofacDocumentation":_vm.ofacDocumentation,"supportEmail":_vm.supportEmail,"brandLogo":_vm.brandLogo}})],1):(_vm.isInitCPStepsDone || !_vm.isAuthProtectedRoute)?_c('div',[(_vm.authenticated)?_c('div',[(!_vm.view.hideAppHeader && !_vm.isMobile)?_c('app-header',{attrs:{"brandDetails":_vm.brandDetails,"logout":_vm.logout,"imageUrl":_vm.imageUrl,"domainList":_vm.domainList}}):_vm._e(),(!_vm.view.hideAppHeader && _vm.isMobile)?_c('div',[_c('app-header-mobile',{attrs:{"brandDetails":_vm.brandDetails,"imageUrl":_vm.imageUrl,"logout":_vm.logout,"type":_vm.DOMAIN_SWITCHER_TYPES.MOBILE}})],1):_vm._e(),(_vm.brandDetails.showWelcomePopup && _vm.isFromNeo)?_c('neo-welcome-screen',{on:{"onClosePopup":function($event){_vm.isFromNeo = false}}}):_vm._e(),_c('AppBanner',{attrs:{"paymentEnabled":_vm.paymentEnabled,"showPlanExpiredError":_vm.showPlanExpiredError,"showSitePlanExpiredError":_vm.showSitePlanExpiredError,"getDnsAndEmailMedusaAttrs":_vm.getDnsAndEmailMedusaAttrs,"addons":_vm.addons,"fetchingEntriDetails":_vm.fetchingEntriDetails},on:{"redirectToPlanUpgrade":_vm.redirectToPlanUpgrade}})],1):_vm._e(),_c('div',{class:{
          main: true,
          'non-iframe-widget': _vm.inWidgetModeWithoutIframe,
        }},[(_vm.authenticated && _vm.isInitCPStepsDone)?_c('SideNav',{ref:"appView",attrs:{"addons":_vm.addons,"brandDetails":_vm.brandDetails,"currency":_vm.currency,"paymentEnabled":_vm.paymentEnabled,"planExpiredError":_vm.showPlanExpiredError,"titanMaxEnabled":_vm.titanMaxEnabled,"view":_vm.view,"imageUrl":_vm.imageUrl}}):_vm._e(),_c('div',{staticClass:"router-container",class:_vm.isAppBannerVisible && _vm.authenticated && !_vm.view.hideSideBar
              ? 'view increase-padding-container'
              : _vm.authenticated
              ? _vm.view.name !== 'defaultView'
                ? `${_vm.view.name}View`
                : 'view'
              : ''},[(
              _vm.view &&
              _vm.view.showModalAsPage &&
              _vm.$route.path !== _vm.view.initialRoute &&
              _vm.$route.name !== _vm.view.home &&
              _vm.showExtraStuffInFooter
            )?_c('div',{staticClass:"widgetViewContainer"},[(!_vm.view?.flags?.hideBreadcrumb && _vm.$route.name !== _vm.view.home)?_c('widget-breadcrumb-nav',{key:_vm.$route.path}):_vm._e(),(!_vm.hideIFrameHelpButton)?_c('a',{class:[
                'help-link-icon',
                { rightAlignIcon: _vm.$route.path === '/' },
              ],attrs:{"target":"_blank","href":_vm.brandDetails.adminGuide}}):_vm._e()],1):_vm._e(),_c('router-view',{attrs:{"setOfacParams":_vm.setOfacParams,"authState":_vm.authenticated ? _vm.authState.AUTH : _vm.authState.NO_AUTH,"addons":_vm.addons,"brandDetails":_vm.brandDetails,"currency":_vm.currency,"domainNotFound":_vm.domainNotFound,"getOtherMedusaAttrb":_vm.getDnsAndEmailMedusaAttrs,"onDNSLookupSuccess":_vm.onDNSLookupSuccess,"partnerId":_vm.partnerId,"partnerURLs":_vm.partnerURLs,"titanMaxEnabled":_vm.titanMaxEnabled,"trials":_vm.trials,"view":_vm.view,"fetchingEntriDetails":_vm.fetchingEntriDetails,"planExpiredError":_vm.showPlanExpiredError},on:{"onLoginSuccess":_vm.onLoginSuccess,"fetchAddons":_vm.fetchAddons,"fetchCardDetails":_vm.fetchCardDetails,"fetchCurrentPlan":_vm.syncCurrentPlan,"fetchEmailAccounts":_vm.fetchEmailAccounts,"fetchPaymentURLDetails":_vm.fetchPaymentURLDetails,"pageLoaded":_vm.pageLoaded}}),(
              _vm.view.showPoweredByTitan &&
              _vm.$route.path !== '/' &&
              _vm.showExtraStuffInFooter
            )?_c('div',{staticClass:"powered-by-titan"},[_c('img',{attrs:{"alt":"powered by","src":require("./assets/powered-by-titan-grey.svg")}})]):_vm._e()],1)],1),(!_vm.authenticated && _vm.isShowSwitcher)?_c('language-switcher'):_vm._e()],1):_c('Loader')],1),_c('widget-toast-screen'),_c('common-modal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }