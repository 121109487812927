<template>
  <div
    :class="[
      'email-created-success',
      { 'inside-iframe': isAppLoadedInsideIframe },
      modalClass,
    ]"
  >
    <div v-if="deletedEmail" class="create-email-modal-banner">
      {{
        $t("emailAccounts.deleteAndCreateNew.successfullyDeleted", [
          deletedEmail,
        ])
      }}
    </div>
    <div
      v-else-if="noOfEmailAccountsCreated > 1"
      class="create-email-modal-banner"
    >
      {{
        $t("emailAccounts.createEmail.numberOfEmailCreated", {
          count: noOfEmailAccountsCreated,
        })
      }}
    </div>
    <div
      :class="[
        'success-banner',
        {
          'modal-page-success-banner': view.showModalAsPage,
        },
      ]"
    >
      <div
        :class="[
          'header flex-align-center',
          { 'mobile-email-creation-success-header': isMobile },
        ]"
      >
        <img
          :src="'email-creation-success' | themeImage"
          alt="email-success"
          width="65px"
          height="78px"
        />
        <span class="email-created email-creation-success-header">
          <span class="font-600 font-24px">{{ email }}</span>
          <br />
          <span class="font-400 font-14px">{{
            $t("emailAccounts.createEmail.successfullyCreated")
          }}</span>
        </span>
      </div>
      <div class="note remove-border" v-if="showDomainBanner">
        <p
          v-html="$t('emailAccounts.createEmail.dnsNote', [this.domainName])"
        />
        <a
          href="/activate?source_hook=email_create_success"
          @click="redirectToConnectDomain"
          ><b>{{ $t("emailAccounts.createEmail.connect") }}</b></a
        >
      </div>
      <div class="email">
        <span class="font-600">{{
          $t("emailAccounts.createEmail.emailLabel")
        }}</span
        >: {{ email }}
      </div>
      <div class="password mt-8">
        <password-actions :password="password" :view="view"></password-actions>
      </div>
      <mail-links
        :email="email"
        :password="password"
        sourceHook="create_email_success"
        :webmailURL="!isMobile ? webmailURL : ''"
        @closeModal="close"
      ></mail-links>

      <div class="margin-gap" v-if="view.showModalAsPage"></div>
      <button
        class="button button__line-blue doneBtn"
        @click="close()"
        v-if="view.showModalAsPage"
      >
        {{ $t("emailAccounts.createEmail.done") }}
      </button>
    </div>
  </div>
</template>

<script>
import { getWebmailUrl, isAppLoadedInsideIframe, isMobile } from "@/helpers";
import { mapGetters } from "vuex";
import MailLinks from "../Utility/MailLinks.vue";
import PasswordActions from "../Utility/PasswordActions.vue";
import router from "@/router";
import { COMMON_MODAL_EMITTERS } from "@/helpers/const";
import eventBus from "@/helpers/event-bus";

export default {
  name: "AccountCreateSuccess",
  components: {
    MailLinks,
    PasswordActions,
  },
  props: {
    email: String,
    password: String,
    modalClass: {
      type: String,
      default: () => "",
      required: false,
    },
    deletedEmail: {
      type: String,
      required: false,
    },
    noOfEmailAccountsCreated: {
      type: Number,
      required: false,
    },
    close: Function,
  },
  computed: {
    ...mapGetters({
      view: "view",
      domainName: "domain/domainName",
      isDomainVerifiedAndConnected: "domain/domainVerifiedAndConnected",
    }),
    showDomainBanner() {
      return !this.isDomainVerifiedAndConnected && !this.isMobile;
    },
  },
  data() {
    const webmailUrlPlaceholder = getWebmailUrl().split("?")[0];
    return {
      isMobile: isMobile(),
      webmailURL: webmailUrlPlaceholder,
      isAppLoadedInsideIframe: isAppLoadedInsideIframe() || true,
    };
  },
  methods: {
    postCloseModal(sourceHook) {
      this.close?.(sourceHook);
    },
    redirectToConnectDomain(e) {
      e?.preventDefault();
      eventBus.$emit(COMMON_MODAL_EMITTERS.CLOSE_MODAL, {
        closeAllModals: true,
        sourceHook: "ConnectDomain",
      });
      router.push({
        path: "activate",
        query: {
          source_hook: "email_create_success",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.email-created-success {
  &:not(.inside-iframe) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .create-email-modal-banner {
    padding: 14px;
    border-radius: 4px;
    background: var(--lightBlueBgBanner);
    margin-bottom: 24px;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
  }

  .note {
    border-radius: 5px;
    border: solid 1px var(--noteBordCol);
    padding: 16px 16px 16px 40px;
    box-sizing: border-box;
    margin: 24px 0;
    font-size: 14px;
    font-weight: normal;
    color: var(--primaryTextCol);
    background-color: var(--warningBgCol);
    background-image: url("../../assets/error-state-2.svg");
    background-position: 12px 14px;
    background-repeat: no-repeat;
    width: auto;
    p {
      margin-bottom: 12px;
      color: var(--genericTextCol);
    }

    a {
      width: auto;
      color: var(--linkBtnCol);
    }
  }

  .mobile-email-creation-success-header {
    flex-direction: column;
    align-items: center;

    .email-created {
      padding-left: 0;
      text-align: center;
    }
  }
}
</style>
