import { COUNTRY } from "@/helpers/const";

export const getCountryCodeFromName = (countryName) => {
  if (!countryName) {
    console.error("Cannot get country code. No country name provided");
    return null;
  }
  return COUNTRY.find((country) => country.name === countryName).code;
};

export const isRestrictedCountry = (countryName) => {
  if (!countryName) {
    console.error(
      "Unable to identify if region is restricted. No country name provided"
    );
    return null;
  }
  return !!COUNTRY.find((country) => country.name === countryName)
    .isBlacklistedForPurchase;
};
