import { isNeoControlPanel } from ".";
import { getDeviceInfo } from "./getDeviceInfo";

export function getMobileOperatingSystem() {
  const deviceInfo = getDeviceInfo();
  return deviceInfo.os.name;
}

export const isIOSDevice = () => {
  const device = getMobileOperatingSystem();
  return device === "iOS";
};

export const _createUserAgentHeader = () => {
  const appVersion = process.env.VUE_APP_BUILD_VERSION;
  const { os, browser } = getDeviceInfo() || {};
  const locale = document.documentElement.getAttribute("lang");
  const client = "control_panel";
  const tp = isNeoControlPanel ? "neo" : "titan";

  return [
    `client=${client}`,
    `tp=${tp}`,
    `os=${os?.name}`,
    `os-version=${os?.version}`,
    `app-version=${appVersion}`,
    `locale=${locale}`,
    `browser-version=${browser?.version}`,
    `browser-name=${browser?.name}`,
  ].join(";");
};

export const getUserAgentHeader = (() => {
  const header = _createUserAgentHeader(); // Generate the header once
  return () => header; // Provide a function to access the cached header
})();
