var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'email-created-success',
    { 'inside-iframe': _vm.isAppLoadedInsideIframe },
    _vm.modalClass,
  ]},[(_vm.deletedEmail)?_c('div',{staticClass:"create-email-modal-banner"},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.deleteAndCreateNew.successfullyDeleted", [ _vm.deletedEmail, ]))+" ")]):(_vm.noOfEmailAccountsCreated > 1)?_c('div',{staticClass:"create-email-modal-banner"},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.createEmail.numberOfEmailCreated", { count: _vm.noOfEmailAccountsCreated, }))+" ")]):_vm._e(),_c('div',{class:[
      'success-banner',
      {
        'modal-page-success-banner': _vm.view.showModalAsPage,
      },
    ]},[_c('div',{class:[
        'header flex-align-center',
        { 'mobile-email-creation-success-header': _vm.isMobile },
      ]},[_c('img',{attrs:{"src":_vm._f("themeImage")('email-creation-success'),"alt":"email-success","width":"65px","height":"78px"}}),_c('span',{staticClass:"email-created email-creation-success-header"},[_c('span',{staticClass:"font-600 font-24px"},[_vm._v(_vm._s(_vm.email))]),_c('br'),_c('span',{staticClass:"font-400 font-14px"},[_vm._v(_vm._s(_vm.$t("emailAccounts.createEmail.successfullyCreated")))])])]),(_vm.showDomainBanner)?_c('div',{staticClass:"note remove-border"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('emailAccounts.createEmail.dnsNote', [this.domainName]))}}),_c('a',{attrs:{"href":"/activate?source_hook=email_create_success"},on:{"click":_vm.redirectToConnectDomain}},[_c('b',[_vm._v(_vm._s(_vm.$t("emailAccounts.createEmail.connect")))])])]):_vm._e(),_c('div',{staticClass:"email"},[_c('span',{staticClass:"font-600"},[_vm._v(_vm._s(_vm.$t("emailAccounts.createEmail.emailLabel")))]),_vm._v(": "+_vm._s(_vm.email)+" ")]),_c('div',{staticClass:"password mt-8"},[_c('password-actions',{attrs:{"password":_vm.password,"view":_vm.view}})],1),_c('mail-links',{attrs:{"email":_vm.email,"password":_vm.password,"sourceHook":"create_email_success","webmailURL":!_vm.isMobile ? _vm.webmailURL : ''},on:{"closeModal":_vm.close}}),(_vm.view.showModalAsPage)?_c('div',{staticClass:"margin-gap"}):_vm._e(),(_vm.view.showModalAsPage)?_c('button',{staticClass:"button button__line-blue doneBtn",on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t("emailAccounts.createEmail.done"))+" ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }