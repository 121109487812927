// const DEFAULT_TRIAL_DURATION_IN_DAYS = 15;

import { PLAN_FEATURE_MAP } from "@/helpers/const";
import { isNeoBrand, isNeoBusinessDomain } from "@/helpers/index";
import * as helper from "@/helpers/index";
import store from "@/store";
import { LITE_PLAN_UNSUPPORTED_BILLING_CYCLES } from "./neo.helper";

const {
  READ_RECEIPTS,
  CONTACT_GROUPS,
  EMAIL_TEMPLATES,
  TITAN_AI,
  MULTIPLE_EXTERNAL_FORWARDER,
  STORAGE,
  NEO_DOMAIN,
  CUSTOM_DOMAIN,
  NEO_SITE,
  MAIL_SEND,
  APPOINTMENT_BOOKING,
  SITE_STORAGE,
  SITE_AI_SITE,
  SITE_AI_TEXT,
  SITE_FONTS,
  SITE_COLORS,
  SITE_PRODUCTS,
  SITE_SERVICES,
  SITE_GALLERY,
  SITE_TESTIMONIALS,
  SITE_STOCK_IMAGES,
  SITE_CONTACT_FORM,
  SITE_SUPPORT,
} = PLAN_FEATURE_MAP;

/**
 * Converts a given string into camelCase format. This function can handle strings
 * with spaces, hyphens, and underscores as separators.
 *
 * @param {string} planName - The string to be converted to camelCase.
 * @returns {string} The camelCase version of the input string.
 *
 * @example
 * planNameToCamelCase('hello_world');
 * planNameToCamelCase('hello world');
 * planNameToCamelCase('hello-world');
 * // returns 'helloWorld'
 *
 */
export const planNameToCamelCase = (planName) => {
  return planName
    .split(/[-_\s]+/)
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");
};

const _sanitizeNeoFeatures = (planList) => {
  if (!isNeoBrand()) return;

  // remove custom domain feature for neo business domain, and neo domain feature for neo custom domains
  const featureToRemove = isNeoBusinessDomain() ? CUSTOM_DOMAIN : NEO_DOMAIN;
  planList.forEach((plan) => {
    plan.features = plan.features.filter(
      (feature) => feature.type !== featureToRemove
    );
  });
};

const formatFeatureValue = (featureKey, attrs) => {
  switch (featureKey) {
    case READ_RECEIPTS:
    case CONTACT_GROUPS:
    case EMAIL_TEMPLATES:
    case TITAN_AI:
    case MULTIPLE_EXTERNAL_FORWARDER:
      if (attrs.limit) {
        return { tx_key: `${featureKey}.val_tx`, val: [attrs.limit] };
      }
      break;

    case STORAGE:
    case SITE_STORAGE:
      if (attrs.quota_in_mb) {
        return {
          tx_key: `${featureKey}.val_tx`,
          val: [helper.toGB(attrs.quota_in_mb)],
        };
      }
      break;

    case CUSTOM_DOMAIN:
      if (attrs) {
        return {
          tx_key: `${featureKey}.val_tx`,
          val: { noOfDays: attrs.trialInDays },
        };
      }
      break;

    case SITE_AI_SITE:
    case SITE_AI_TEXT:
      if (attrs) {
        return {
          tx_key: !attrs?.monthlyLimit
            ? `${featureKey}.unlimited`
            : `${featureKey}.val_tx`,
          val: { monthlyLimit: attrs.monthlyLimit },
        };
      }
      break;

    case SITE_FONTS:
      if (attrs) {
        return {
          tx_key: `${featureKey}.val_tx`,
          val: { type: attrs.premium === "true" ? "Premium" : "Standard" },
        };
      }
      break;

    case SITE_COLORS:
      if (attrs) {
        return {
          tx_key:
            attrs.curated === "true"
              ? `${featureKey}.curated`
              : `${featureKey}.yes`,
        };
      }
      break;

    case SITE_TESTIMONIALS:
    case SITE_STOCK_IMAGES:
      return {
        tx_key: !attrs?.limit
          ? `${featureKey}.unlimited`
          : `${featureKey}.val_tx`,
        val: { limit: attrs.limit },
      };

    case SITE_CONTACT_FORM:
      return {
        tx_key: !attrs?.monthlyLimit
          ? `${featureKey}.unlimited`
          : `${featureKey}.val_tx`,
        val: { monthlyLimit: attrs.monthlyLimit },
      };

    case SITE_PRODUCTS:
    case SITE_SERVICES:
    case SITE_GALLERY:
      if (attrs) {
        return {
          tx_key: !attrs?.limit
            ? `${featureKey}.unlimited`
            : `${featureKey}.val_tx`,
          val: { limit: attrs.limit },
        };
      }
      break;

    case SITE_SUPPORT:
      return {
        tx_key:
          attrs?.priority === "true"
            ? `${featureKey}.priority_chat`
            : `${featureKey}.support`,
      };

    case NEO_SITE:
      if (attrs) {
        return {
          tx_key: `${featureKey}.val_tx`,
        };
      }
      break;

    case MAIL_SEND:
      if (attrs) {
        return {
          tx_key: `${featureKey}.val_tx`,
          val: { hourlyLimit: attrs.hourlyLimit, dailyLimit: attrs.dailyLimit },
        };
      }
      break;

    default:
      return null;
  }
};

const _isPaidCounterpartOfCurrentPlan = (plan, currentPlan) => {
  return currentPlan.paidPlanId === plan.id;
};

export const addPlanTrialInfo = (allPlans, currentPlan) => {
  return allPlans.map((plan) => {
    plan.showTrialInfo = _isPaidCounterpartOfCurrentPlan(plan, currentPlan);
    return plan;
  });
};

export const getUserVisiblePlans = (allPlans, currentPlan) => {
  return allPlans.filter((plan) => {
    if (plan.id === currentPlan.planId)
      // Show current plan in list only if it is not under trial
      return !currentPlan.isTrial;
    return true;
  });
};

const getDefaultFeatureName = (featureKey) => {
  // Make first letter uppercase for each word in featureKey
  return featureKey
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getPlanFeaturesComparison = (plansToShow, featConfig) => {
  _sanitizeNeoFeatures(plansToShow);

  plansToShow.sort((plan1, plan2) => plan1.zIndex - plan2.zIndex);

  const featureGroupsMap = new Map();

  // Process each plan and its features
  plansToShow.forEach((plan, index) => {
    plan.features.forEach((feature) => {
      const featureGroup = feature.featureGroup || "";
      const displayOrder = feature.displayOrder;

      if (
        Object.prototype.hasOwnProperty.call(
          featConfig,
          `show_${feature.type}`
        ) &&
        !featConfig[`show_${feature.type}`]
      )
        return;

      if (!featureGroupsMap.has(featureGroup)) {
        featureGroupsMap.set(featureGroup, []);
      }

      let featureList = featureGroupsMap.get(featureGroup);
      let featureItem = featureList.find((f) => f.featureKey === feature.type);

      if (!featureItem) {
        featureItem = { featureKey: feature.type, plans: [], displayOrder };
        featureList.push(featureItem);
      }

      // Use formatFeatureValue to determine the value to set
      const formattedValue = formatFeatureValue(feature.type, feature.attrs);
      featureItem.plans[index] =
        formattedValue || featureItem.plans[index] || true;

      // Set display name for feature, if translation is not available
      featureItem.displayName = getDefaultFeatureName(feature.type);

      // If the plan is not ultra, disable appointment booking, as it is only available in ultra plan
      if (
        isNeoBrand() &&
        !plan.type.includes("ultra") &&
        feature.type === APPOINTMENT_BOOKING
      ) {
        featureItem.plans[index] = false;
      }
    });

    plan.features.sort((a, b) => a.displayOrder - b.displayOrder);
  });

  // Sort features within each group by display order
  featureGroupsMap.forEach((features) => {
    features.sort((a, b) => a.displayOrder - b.displayOrder);
  });

  // Convert Map to Array, include min display order for sorting groups
  const featureGroupsArray = Array.from(
    featureGroupsMap,
    ([featureGroupName, features]) => ({
      featureGroupName,
      features,
      minDisplayOrder: Math.min(...features.map((f) => f.displayOrder)),
    })
  );

  // Sort groups based on min display order
  featureGroupsArray.sort((a, b) => a.minDisplayOrder - b.minDisplayOrder);

  const groupedFeatures = featureGroupsArray.map((group) => ({
    categoryName: group.featureGroupName,
    features: group.features,
  }));

  return {
    groupedFeatures,
  };
};

export const isCurrentPlanFree = () => {
  const currentPlan = store.getters.currentPlanDetails;
  return isFreePlan(currentPlan);
};

export const isLitePlan = (plan) => plan.type?.indexOf("lite") !== -1;

export const isFreePlan = (plan) => !plan?.isTrial && !plan?.isPaid;

export const isTrialPlan = (plan) => plan?.isTrial && !plan?.isPaid;

export const getLowestPlan = (plans) => {
  return plans?.sort((p1, p2) => p1.zIndex - p2.zIndex)?.[0] || null;
};

export const isFreeBetaPlan = (plan) => {
  return isFreePlan(plan) && isNeoBrand();
};

export const filterFreeOrBetaPlan = (plan) =>
  !(isFreePlan(plan) || isFreeBetaPlan(plan));

export const filterLowerPlans = (currentPlanZIndex) => {
  return (p) => {
    return p.zIndex >= (currentPlanZIndex || 0);
  };
};

export const filterLitePlanForShorterBillingCycle = (currentPlan = {}) => {
  const { billingCycle, renewalBillingCycle } = currentPlan;
  return (p) => {
    if (!isNeoBrand() || !isLitePlan(p)) return true;

    return (
      // do not show lite plan if current billing cycle falls in `LITE_PLAN_UNSUPPORTED_BILLING_CYCLES`
      LITE_PLAN_UNSUPPORTED_BILLING_CYCLES.indexOf(billingCycle) === -1 &&
      LITE_PLAN_UNSUPPORTED_BILLING_CYCLES.indexOf(renewalBillingCycle) === -1
    );
  };
};
