<template>
  <div id="app" :data-theme="theme">
    <mobile-screen v-if="!shouldShowResponsiveLayout" />
    <div v-else>
      <div v-if="isOfacFlag">
        <Ofac
          :ofacDocumentation="ofacDocumentation"
          :supportEmail="supportEmail"
          :brandLogo="brandLogo"
        />
      </div>
      <!-- incase of not logged-in then `isAuthProtectedRoute` true and vue-router open corresponding route -->
      <!-- incase of logged-in we wait for `isInitCPStepsDone` to be truthy to make sure all necessary data is set in app store -->
      <div v-else-if="isInitCPStepsDone || !isAuthProtectedRoute">
        <div v-if="authenticated">
          <app-header
            :brandDetails="brandDetails"
            :logout="logout"
            :imageUrl="imageUrl"
            :domainList="domainList"
            v-if="!view.hideAppHeader && !isMobile"
          />

          <div v-if="!view.hideAppHeader && isMobile">
            <app-header-mobile
              :brandDetails="brandDetails"
              :imageUrl="imageUrl"
              :logout="logout"
              :type="DOMAIN_SWITCHER_TYPES.MOBILE"
            />
          </div>

          <!-- TODO: this modal implementation is not as per the expectation we wil revisit separately on this -->
          <!-- <domain-admin-welcome-screen
            v-if="domainList.length > 0 && isDomainAdmin"
          /> -->

          <!-- once you fix above TODO make sure to set if-else order -->
          <neo-welcome-screen
            v-if="brandDetails.showWelcomePopup && isFromNeo"
            @onClosePopup="isFromNeo = false"
          />

          <!-- application banner - mail | DNS | site -->
          <AppBanner
            :paymentEnabled="paymentEnabled"
            :showPlanExpiredError="showPlanExpiredError"
            :showSitePlanExpiredError="showSitePlanExpiredError"
            :getDnsAndEmailMedusaAttrs="getDnsAndEmailMedusaAttrs"
            :addons="addons"
            :fetchingEntriDetails="fetchingEntriDetails"
            @redirectToPlanUpgrade="redirectToPlanUpgrade"
          />
        </div>
        <div
          :class="{
            main: true,
            'non-iframe-widget': inWidgetModeWithoutIframe,
          }"
        >
          <SideNav
            v-if="authenticated && isInitCPStepsDone"
            :addons="addons"
            :brandDetails="brandDetails"
            :currency="currency"
            :paymentEnabled="paymentEnabled"
            :planExpiredError="showPlanExpiredError"
            :titanMaxEnabled="titanMaxEnabled"
            :view="view"
            :imageUrl="imageUrl"
            ref="appView"
          />
          <div
            class="router-container"
            :class="
              isAppBannerVisible && authenticated && !view.hideSideBar
                ? 'view increase-padding-container'
                : authenticated
                ? view.name !== 'defaultView'
                  ? `${view.name}View`
                  : 'view'
                : ''
            "
          >
            <div
              v-if="
                view &&
                view.showModalAsPage &&
                $route.path !== view.initialRoute &&
                $route.name !== view.home &&
                showExtraStuffInFooter
              "
              class="widgetViewContainer"
            >
              <widget-breadcrumb-nav
                :key="$route.path"
                v-if="!view?.flags?.hideBreadcrumb && $route.name !== view.home"
              />
              <!-- <h4 v-else-if="$route.name === view.home">
                {{ $t("emailAccounts.createEmail.emailLabel") }}
                {{ domainName !== "" ? "@" + domainName : "" }}
              </h4> -->
              <a
                :class="[
                  'help-link-icon',
                  { rightAlignIcon: $route.path === '/' },
                ]"
                target="_blank"
                v-if="!hideIFrameHelpButton"
                :href="brandDetails.adminGuide"
              />
            </div>
            <router-view
              @onLoginSuccess="onLoginSuccess"
              :setOfacParams="setOfacParams"
              @fetchAddons="fetchAddons"
              @fetchCardDetails="fetchCardDetails"
              @fetchCurrentPlan="syncCurrentPlan"
              @fetchEmailAccounts="fetchEmailAccounts"
              @fetchPaymentURLDetails="fetchPaymentURLDetails"
              @pageLoaded="pageLoaded"
              :authState="authenticated ? authState.AUTH : authState.NO_AUTH"
              :addons="addons"
              :brandDetails="brandDetails"
              :currency="currency"
              :domainNotFound="domainNotFound"
              :getOtherMedusaAttrb="getDnsAndEmailMedusaAttrs"
              :onDNSLookupSuccess="onDNSLookupSuccess"
              :partnerId="partnerId"
              :partnerURLs="partnerURLs"
              :titanMaxEnabled="titanMaxEnabled"
              :trials="trials"
              :view="view"
              :fetchingEntriDetails="fetchingEntriDetails"
              :planExpiredError="showPlanExpiredError"
            />
            <div
              class="powered-by-titan"
              v-if="
                view.showPoweredByTitan &&
                $route.path !== '/' &&
                showExtraStuffInFooter
              "
            >
              <img alt="powered by" src="./assets/powered-by-titan-grey.svg" />
            </div>
          </div>
        </div>
        <language-switcher
          v-if="!authenticated && isShowSwitcher"
        ></language-switcher>
      </div>
      <Loader v-else />
    </div>
    <widget-toast-screen />
    <common-modal />
  </div>
</template>

<script>
import AppHeaderMobile from "@/components/Utility/AppHeaderMobile.vue";
import CommonModal from "@/components/Utility/CommonModal.vue";
import DomainSwitcher from "@/components/Utility/DomainSwitcher.vue";
import { DOMAIN_SWITCHER_TYPES } from "@/helpers/domainHelpers";
import { addPlanTrialInfo } from "@/helpers/plansHelpers";
import bundleEvents from "@/telemetry/bundleEvents";
import brand from "./helpers/brand";
import {
  ADDONS_ON_TRIAL,
  CONTENT_DIMENSION_DID_UPDATE,
  EMAIL_ACCOUNTS_CHANGED,
  IFRAME_WIDTH_CHANGED,
  bindCurrencySymbol,
  clearAuthSession,
  dayDiff,
  formatDate,
  getCookie,
  getCurrencySymbol,
  getDefaultCurrency,
  getFontForPartner,
  isAppLoadedInsideIframe,
  isMobile,
  sendPostMessageToParentWindow,
  setCookie,
  setWebsiteMetaData,
  setViewAndTheme,
  getJWTpayloadData,
  getFilteredQueryParams,
  hyphenToCamelCase,
  hardRedirect,
  getAppSource,
  shouldShowResponsiveCP,
  setRootAuthHeaders,
  isNeoCustomDomain,
  parseDnsRecords,
  getCurrentUserEmail,
  smartStorage,
} from "./helpers";
import viewTypes from "./views";
import eventBus from "./helpers/event-bus";
import throttle from "lodash/throttle";
import { mapGetters, mapActions } from "vuex";
const AppHeader = () => import("./components/Utility/AppHeader.vue");
const AppBanner = () => import("./components/Banner/AppBanner.vue");
const SideNav = () => import("./components/Utility/SideNav.vue");
const Login = () => import("./components/Auth/Login.vue");
const LanguageSwitcher = () => import("./components/Utility/LangSwitcher.vue");
const Ofac = () => import("./components/Ofac/Ofac.vue");
const Loader = () => import("./components/Utility/Loader.vue");
const MobileScreen = () => import("./components/MobileScreen/MobileScreen.vue");
import { loadCurrentLanguageFile } from "./i18n";
import {
  sendOrderAndCustomerEvent,
  getCommonMedusaParams,
} from "@/telemetry/medusaUtils";
import DomainAdminWelcomeScreen from "@/components/Auth/DomainAdminWelcomeScreen.vue";
import NeoWelcomeScreen from "@/components/Auth/NeoWelcomeScreen.vue";
import {
  AUTH_STATE,
  ERROR_BANNER_TYPE,
  FLOW_TYPES,
  ROUTE_ACTIONS,
  ROUTE_ACTIONS_PROPS,
} from "./helpers/const";
import { ENTRI_SETUP_TYPE } from "./helpers/entriConfig";
import { getEntriSetupUrl, isSiteSetupMode } from "./helpers/entri";
import { MEDUSA_EVENTS_MAP } from "./telemetry/events";
import { measureInitialAppLoad } from "../src/analytics/performance/pageTimings";
import queryParamStore from "./global/queryParamStore";
import emailService from "@/services/email/email.service";
import domainService from "@/services/domain/domain.service";
import billingService from "@/services/billing/billing.service";
import entriService from "@/services/entri/entri.service";
import authService from "@/services/auth/auth.service";
import { initGtm } from "@/global/lib/gtm";
import Logger from "./helpers/logger";
import {
  formatDomainDetails,
  formatProductDetails,
  getProductsFromCurrentPlan,
  hasDomainProduct,
  hasMailSuiteProduct,
  hasSiteProduct,
  PRODUCTS,
} from "./helpers/product";

let observedElementDetails = { element: null, observer: null };

export default {
  name: "App",
  components: {
    AppBanner,
    DomainSwitcher,
    AppHeaderMobile,
    CommonModal,
    AppHeader,
    LanguageSwitcher,
    Loader,
    Login,
    MobileScreen,
    Ofac,
    SideNav,
    DomainAdminWelcomeScreen,
    NeoWelcomeScreen,
  },
  data() {
    return {
      addons: [],
      authenticated: false,
      brandDetails: brand.getDetails(),
      brandLogo: null,
      cr: bindCurrencySymbol.bind(this),
      domainList: [],
      enableDNSVerification: false,
      formatDate,
      isFetching: true,
      failedToInitCP: false,
      hideIFrameHelpButton: false,
      imageUrl: "",
      isFromWebmail: false,
      isFromNeo: false,
      isMobile: isMobile(),
      isOfacFlag: false,
      isShowSwitcher: false,
      isTitanMail: false,
      loginType: null,
      lookupData: null,
      fetchingEntriDetails: false,
      ofacDocumentation: "",
      partnerId: null,
      partnerURLs: {},
      paymentEnabled: false,
      rebrandedToTitan: false,
      showExtraStuffInFooter: false,
      supportEmail: "",
      theme: null,
      shouldShowResponsiveLayout: true,
      isEntriDetailsFetched: false,
      authState: AUTH_STATE,
    };
  },
  computed: {
    DOMAIN_SWITCHER_TYPES() {
      return DOMAIN_SWITCHER_TYPES;
    },
    ...mapGetters([
      "hideBilling",
      "locale",
      "trials",
      "view",
      "showDomainAdminPopup",
      "entriSetupPossible",
      "partnerInfo",
      "products",
      "isAppBannerVisible",
    ]),
    ...mapGetters({
      domainID: "domain/domainID",
      domainName: "domain/domainName",
      domainConnectData: "domain/domainConnectData",
      domainOwnershipVerified: "domain/domainOwnershipVerified",
      domainMXRecordVerified: "domain/domainMXRecordVerified",
      domainSpfVerified: "domain/domainSpfRecordVerified",
      domainVerifiedAndConnected: "domain/domainVerifiedAndConnected",
      domainDkimVerified: "domain/domainDkimVerified",
      domainARecordVerified: "domain/domainARecordVerified",
      domainCNameRecordVerified: "domain/domainCNameRecordVerified",
      siteVerifiedAndConnected: "domain/siteVerifiedAndConnected",
      createdEmailAccounts: "currentDomainMailboxDetails",
      mailPlanList: "mail/plans",
      sitePlanList: "site/plans",
      domainPlanList: "domain/plans",
      currentPlan: "currentPlanDetails",
      currentPlanMailDetails: "mail/currentProductDetails",
      isFreeMailPlan: "mail/isFreePlan",
      isFreeSitePlan: "site/isFreePlan",
      mailPlanType: "mail/currentPlanType",
    }),
    isInitCPStepsDone() {
      return (
        // this is to make sure all sub routes has access to store values
        // so we will wait until data is being fetched and set
        !this.isFetching &&
        (!!this.currentPlan?.orderDetails || this.failedToInitCP)
      );
    },
    isAuthProtectedRoute() {
      return this.$route.meta.requiresAuth;
    },
    domainNotFound() {
      return this.domainConnectData.DomainNotFound;
    },
    inWidgetModeWithoutIframe() {
      return this.view.name === "widget" && !isAppLoadedInsideIframe();
    },
    amountToBePaidOnRenewal() {
      return this.currentPlan.amountToBePaidOnRenewal;
    },
    brandName() {
      return this.brandDetails.name;
    },
    expiresOn() {
      return this.currentPlan.expiresOn;
    },
    daysUntilExpiry() {
      return dayDiff(this.expiresOn);
    },
    hasPlanExpired() {
      return this.daysUntilExpiry < 0;
    },
    showPlanExpiredError() {
      return this.showMailPlanExpiredError
        ? PRODUCTS.MAIL_SUITE
        : this.showSitePlanExpiredError
        ? PRODUCTS.SITE
        : "";
    },
    showMailPlanExpiredError() {
      return (
        hasMailSuiteProduct(this.products) &&
        this.hasPlanExpired &&
        (!this.isFreeMailPlan ||
          (this.isFreeMailPlan && this.amountToBePaidOnRenewal > 0))
      );
    },
    showSitePlanExpiredError() {
      return (
        hasSiteProduct(this.products) &&
        this.hasPlanExpired &&
        (!this.isFreeSitePlan ||
          (this.isFreeSitePlan && this.amountToBePaidOnRenewal > 0))
      );
    },
    titanMaxEnabled() {
      return !!this.addons.find((a) =>
        this.view.addonsWithFakedoor.includes(a.type)
      );
    },
    currency() {
      const currencyCode =
        (this.currentPlan && this.currentPlan.currency) ||
        this.$root.defaultCurrency;
      return getCurrencySymbol(currencyCode);
    },
  },
  methods: {
    ...mapActions({
      setDomainConnectData: "domain/domainConnectData",
      setEntriSetupPossible: "entriSetupPossible",
      setEntriDnsProvider: "entriDnsProvider",
      setCardType: "paymentCardType",
    }),
    onIframeDimensionChange: throttle(
      function (event) {
        const eventName = event.data.event;
        if (eventName === IFRAME_WIDTH_CHANGED) {
          const element = document.getElementById("app");
          sendPostMessageToParentWindow(CONTENT_DIMENSION_DID_UPDATE, {
            height: element.scrollHeight,
            width: element.scrollWidth,
          });
        }
      },
      2000,
      { trailing: true }
    ),
    observeChangesInDOM(element) {
      const observer = new ResizeObserver(
        throttle(
          (entries) => {
            for (let entry of entries) {
              const element = entry.contentRect;
              sendPostMessageToParentWindow(CONTENT_DIMENSION_DID_UPDATE, {
                height: element.height,
                width: element.width,
              });
            }
          },
          2000,
          { trailing: true }
        )
      );
      observer.observe(element);
      return { element, observer };
    },
    /**
     * Triggers an analytics event to Medusa with the login information.
     */
    triggerLoginEvent() {
      var eventObj = {
        eventName: "control_panel_logged_in",
        auto_login: this.loginType === "autoLogin",
        cp_type: isAppLoadedInsideIframe() ? "iframe" : "normal",
        browser_locales: navigator.languages,
        titan_locale: document.documentElement.getAttribute("lang"),
        partner_id: this.partnerId,
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          eventObj,
          this.domainName,
          "flock_order"
        )
      );
      bundleEvents.logCPLoggedInEvent({
        ...eventObj,
        cp_locale: document.documentElement.getAttribute("lang"),
      });
    },
    async onLoginSuccess(loginType, partnerId) {
      this.setViewAndTheme();
      this.loginType = loginType;
      this.partnerId = partnerId;
      await this.initCP();
      this.triggerLoginEvent();
    },
    pageLoaded(flag) {
      this.showExtraStuffInFooter = flag;
    },
    /**
     * Sets the user's locale to the domain set by backend.
     * Locale preference is in the following order:
     * 1. locale parameter from the query string.
     * 2. locale parameter from panel/domain/list response
     * 3. locale from JWT (possibly deprecated)
     * 4. locale from browser
     * The locale from JWT is deprecated as backend folks claim there was never any locale in JWT.
     * @param {String} domainLocale - the locale of the domain response
     */
    setLocale(domainLocale) {
      const { locale: queryLocale, jwt: token } = this.$route.query;
      const { locale: jwtLangLocale } = token ? getJWTpayloadData(token) : {};
      loadCurrentLanguageFile(
        queryLocale || domainLocale || jwtLangLocale || navigator.language
      );
    },
    setViewAndTheme() {
      const viewAndThemeObj = setViewAndTheme(
        this.$route.query.view,
        this.$route.meta.view,
        this.$route.query.customTheme,
        this.$route.meta.customTheme
      );
      this.$store.dispatch("view", viewTypes[viewAndThemeObj.view]);
      this.theme = viewAndThemeObj.theme;
      this.$store.dispatch("theme", this.theme);
      getFontForPartner(this.theme);
      if (this.view.hideScrollbar) {
        document.body.classList.add("without-scrollbar");
        document.documentElement.style.scrollbarWidth = "none"; // Firefox
      }
    },
    setOfacParams(ofacObj) {
      const {
        isOfacFlag,
        brandLogo,
        supportEmail,
        ofacDocumentation,
      } = ofacObj;
      this.isOfacFlag = isOfacFlag;
      this.brandLogo = brandLogo;
      (this.supportEmail = supportEmail),
        (this.ofacDocumentation = ofacDocumentation);
    },
    getDnsAndEmailMedusaAttrs() {
      const otherInfo = this.lookupData
        ? {
            mx: (this.lookupData.mxRecords || []).join(" , "),
            spf: (this.lookupData.spfRecords || []).join(" , "),
            ns: (this.lookupData.nsRecords || []).join(" , "),
            a: (this.lookupData.aRecords || []).join(" , "),
            domainconnect: this.lookupData.isDomainConnectDomain,
          }
        : {};

      return {
        adminEmail: getCurrentUserEmail(),
        no_of_pop_email_created: this.createdEmailAccounts?.length || 0,
        ...otherInfo,
      };
    },
    handleEntriSetupRedirection(setupType, setupFor) {
      const isAutomaticSetupType = setupType === ENTRI_SETUP_TYPE.AUTOMATIC;

      // medusa events
      const commonMedusaAttribute = {
        auto_domain_setup_supported: this.entriSetupPossible,
        connection_method: setupType,
      };

      if (!isSiteSetupMode(setupFor)) {
        this.medusaEvents.trackEvent({
          eventName: "mx_banner_clicked",
          source: getAppSource(),
          ...getCommonMedusaParams(),
          ...this.getDnsAndEmailMedusaAttrs(),
          ...commonMedusaAttribute,
        });
      }

      // if Entri Automatic setup possible
      if (isAutomaticSetupType) {
        const dnsSetupUrl = this.partnerInfo?.urls?.dnsRecordSetupUrl;
        const domainName = this.domainName;
        const domainId = this.domainID;
        hardRedirect(
          getEntriSetupUrl({
            setupFor,
            dnsSetupUrl,
            domainName,
            domainId,
          }),
          true,
          true
        );
      }
    },
    redirectToPlanUpgrade(bannerType, action) {
      const isNeoPaymentFailure =
        ERROR_BANNER_TYPE.SUBSCRIPTION_EXPIRED_PAYMENT_FAILURE === bannerType;

      if (isNeoPaymentFailure) {
        // open payment card section
        queryParamStore.set(ROUTE_ACTIONS.RENEW_CARD_PAYMENT, true);
      }

      this.$refs.appView.getJWTpayloadForUpgrade(
        this.showMailPlanExpiredError
          ? "/billing-and-upgrade"
          : "/site-billing-and-upgrade",
        true,
        {
          [action]: true,
        }
      );
    },
    authenticateExternalToken() {
      const externalToken = smartStorage.getItem("auth");
      return authService
        .authenticateExternalToken(this.domainName, externalToken)
        .then(this.onExtAuthSuccess)
        .catch(this.onExtAuthFailed);
    },
    onExtAuthSuccess(data) {
      if (data) {
        this.initCP();
      }
    },
    onExtAuthFailed(response) {
      if (response && response.data && response.data.desc) {
        this.logout();
        this.showToast(response.data.desc);
      }
    },
    async initCP() {
      try {
        this.failedToInitCP = false;
        setRootAuthHeaders.call(this);

        await Promise.all([this.fetchPartnerInfo(), this.getAllDomains()]);

        this.authenticated = true;

        const currentPlanData = await this.fetchCurrentPlan();

        const currentDomain = this.getCurrentDomainDetails();
        this.$root.defaultCurrency = getDefaultCurrency();

        await Promise.all([
          this.fetchPlans(currentPlanData?.currency),
          this.fetchPaymentURLDetails(),
          this.fetchCardDetails(),
          this.fetchEmailAccounts(),
          this.fetchAddons(),
        ]);

        const products = getProductsFromCurrentPlan(currentPlanData);
        this.setCurrentPlanDetails(currentPlanData, products);
        this.informPendo(this.partnerId, currentDomain.createdAt);
      } catch (e) {
        Logger.error("Error in `initCP`", e);
        this.failedToInitCP = true;
      } finally {
        this.hideAppLoader();
      }
    },
    fetchPartnerInfo() {
      return this.getPartnerInfo()
        .then(this.onSuccessPartnerFetch)
        .catch(this.onErrorPartnerFetch);
    },
    fetchPlans(currency) {
      return billingService
        .getAllPlans(currency || this.currentPlan.currency)
        .then(this.onFetchPlanSuccess)
        .catch(this.forceLogout);
    },
    syncAllProductPlans() {
      return this.fetchPlans();
    },
    getCurrentDomainDetails() {
      return this.getDomainDetails(this.domainName);
    },
    getDomainDetails(domain) {
      return this.domainList.find((ele) => ele.name === domain);
    },
    getMailPlanDetails(planId) {
      return this.mailPlanList.find((p) => p.id === planId);
    },
    getSitePlanDetails(planId) {
      return this.sitePlanList.find((p) => p.id === planId);
    },
    getDomainPlanDetails(planId) {
      return (
        this.domainPlanList.find((p) => p.id === planId) ||
        this.getMailPlanDetails(planId)
        // fallback is for old orders incase plan is not present in domainPlanList then check in mail plans
      );
    },
    forceLogout(e) {
      // in case the app is not inside an iframe, log the user out.
      // if it's loaded inside an iframe, no need to do anything as the
      // partner side handles data transfer to the iframe
      if (!isAppLoadedInsideIframe()) {
        this.logout();
      } else {
        this.$router.replace({ name: "LoginFailureScreen" });
      }

      this.$store.dispatch("mail/plans", []);
      this.$store.dispatch("site/plans", []);
      return Promise.reject(e);
    },
    onFetchPlanSuccess(productPlans) {
      return Promise.resolve(productPlans);
    },
    async logout() {
      var eventObj = {
        eventName: "control_panel_logout_button_click",
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(
          eventObj,
          this.domainName,
          "flock_order"
        )
      );
      try {
        await authService.logout();
        bundleEvents.logCPLoggedOutEvent();
        this.$router.push({ name: "Login" });
        this.authenticated = false;
      } catch (e) {
        console.error("Error in logout", e);
        this.showToast(
          "Somnething went wrong while logging out. Please try again later."
        );
      }
    },
    onErrorPartnerFetch(e) {
      this.isTitanMail = brand.deriveBrandFromURL() === brand.NAMES.TITAN;

      if (this.isTitanMail) {
        this.setDNSInfo();
      } else {
        this.setBrandDetails();
      }

      setWebsiteMetaData.call(this);
      return Promise.reject(e);
    },
    onSuccessPartnerFetch(data) {
      this.partnerId = data.partnerId;
      this.$store.dispatch("partnerId", data.partnerId);
      this.$store.dispatch("partnerName", data.companyName);
      this.$store.dispatch("theme", data.resources?.cpThemeId);
      this.$store.dispatch("partnerInfo", data);
      this.supportEmail = data.emails && data.emails.supportEmail;
      this.ofacDocumentation = data && data.urls && data.urls.ofacSanctionGuide;
      this.hideIFrameHelpButton =
        (data.flags.hideIFrameHelpButton && isAppLoadedInsideIframe()) || false;
      this.imageUrl =
        data?.resources?.controlPanelInvertedLogo ||
        data?.resources?.controlPanelLogoUrl;
      smartStorage.webmail = this.setSafeValues(data, ["urls", "webmail"]);
      if (smartStorage.getItem("webmail") === "undefined") {
        // eslint-disable-next-line no-undef
        smartStorage.webmail = process.env.VUE_APP_OPEN_WEBMAIL_URL;
      }

      const { urls = {}, flags = {}, emails = {} } = data;
      this.$root.partnerURLs = urls;
      this.partnerURLs = { ...urls };
      this.paymentEnabled =
        this.setSafeValues(data, ["paymentEnabled"]) || true;
      smartStorage.setItem("paymentEnabled", this.paymentEnabled || false);
      this.isTitanMail = flags.rebrandedToTitan;
      this.rebrandedToTitan = flags.rebrandedToTitan;
      this.enableDNSVerification = flags.enableDNSVerification;

      const brandOtherInfo = {
        supportEmail: emails.supportEmail,
        adminGuide: urls.adminGuide,
        billing: {
          termsOfUse: urls.termsOfUse,
          privacyPolicy: urls.privacyPolicy,
        },
      };

      if (this.enableDNSVerification) {
        // dns config is a common API and adding domain connect param to every dns config
        // call could add significant load system since it has external checks involved
        // so better to keep this flag based
        // currently checkDomainConnect would be enabled for godaddy (where forgoPayments flag is set)
        if (flags.forgoPayments) {
          smartStorage.setItem("checkDomainConnect", true);
        }

        this.setDNSInfo(brandOtherInfo);
      } else {
        this.setBrandDetails(brandOtherInfo);
      }

      setWebsiteMetaData.call(this);
      return Promise.resolve(data);
    },
    informPendo(partnerId, domainCreatedOn) {
      const domainID = this.domainID;
      // eslint-disable-next-line no-undef
      pendo.initialize({
        visitor: {
          id: `${domainID}-order`,
          email: smartStorage.getItem("loginEmail"),
          // eslint-disable-next-line no-undef
          visitorSource: process.env.VUE_APP_PENDO_SOURCE,
          visitorType: "order",
        },
        account: {
          id: domainID,
          // eslint-disable-next-line no-undef
          accountSource: process.env.VUE_APP_PENDO_SOURCE,
          cpDomainName: this.domainName,
          cpPartnerId: partnerId,
          cpPlan: this.mailPlanType,
          cpDomainCreatedOn: domainCreatedOn,
        },
      });
    },
    onDNSLookupSuccess(data) {
      this.lookupData = data;
      this.setDomainConnectData(data);
      const domainConnectStatus = getCookie("domainConnectStatus");
      const prevDomainConnectStatus = getCookie("prevDomainConnectStatus");
      const currentDomainConnectStatus = `${data.mxVerified}-${data.spfVerified}`;
      const resultChanged =
        domainConnectStatus === "pending" &&
        prevDomainConnectStatus !== currentDomainConnectStatus;
      if (domainConnectStatus !== "pending" || resultChanged) {
        setCookie("domainConnectStatus", "", -1);
        setCookie("prevDomainConnectStatus", "", -1);
      }

      this.setBrandDetails(
        parseDnsRecords(data.expectedRecords, this.domainName)
      );
      const mxVerified = this.domainMXRecordVerified;
      const spfVerified = this.isDomainSpfVerified;

      if (mxVerified) {
        smartStorage.setItem("showCongratsNote", !!resultChanged);
        setCookie("domainConnectStatus", "", -1);
        setCookie("prevDomainConnectStatus", "", -1);
      } else if (isNeoCustomDomain()) {
        // if neo custom domain mx is not verified then check for entri setup
        this.getEntriSetupDetails();
      }

      this.fetchEntriDetailsIfMissing();

      if (mxVerified && spfVerified) {
        // once the mx and spf verified we dont need to keep
        // domain connect param in dns config api
        smartStorage.setItem("checkDomainConnect", false);
      }
    },
    fetchEntriDetailsIfMissing() {
      if (this.isEntriDetailsFetched) return false;

      const aVerified = this.domainARecordVerified;
      const cnameVerified = this.domainCNameRecordVerified;

      // check for site records
      if (isNeoCustomDomain() && !(aVerified && cnameVerified)) {
        this.getEntriSetupDetails();
      }
    },
    async getEntriSetupDetails() {
      if (!this.domainConnectData?.expectedRecords) return false;

      this.fetchingEntriDetails = true;
      entriService
        .checkDomain(this.domainName, this.domainConnectData.expectedRecords)
        .then((response) => {
          if (process.env.VUE_APP_ENV_FLAG !== "prod") {
            /* Log entri check domain response only on staging and development. */
            Logger.info("Entri response", response);
          }

          this.isEntriDetailsFetched = true;
          this.setEntriDnsProvider(response?.provider || "");

          // This might not needed as we do call this event on neo signup app
          sendOrderAndCustomerEvent.call(
            this,
            {
              eventName: MEDUSA_EVENTS_MAP.ENTRI_DOMAIN_CHECKED,
              domain_supported:
                response.setupType === ENTRI_SETUP_TYPE.AUTOMATIC,
              source_hook: "Control panel",
              domain_provider: response.provider,
            },
            false
          );
        })
        .finally(() => {
          this.fetchingEntriDetails = false;
        });
    },
    async fetchEmailAccounts() {
      if (!hasMailSuiteProduct(this.products)) return Promise.resolve([]);

      const data = await emailService.getEmailList();
      if (data?.accounts?.length) {
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            {
              eventName: "mx_banner_shown",
              source: getAppSource(),
              ...this.getDnsAndEmailMedusaAttrs(),
            },
            this.domainName,
            "flock_order"
          )
        );
      }
      return data;
    },
    onDnsLookupError() {
      this.setDomainConnectData({});
      this.setBrandDetails({
        mxItems: [],
        spfItems: [],
        domainOwnershipItems: [],
      });
      this.hideAppLoader();
    },
    setDNSInfo(otherInfo) {
      this.setBrandDetails(otherInfo);
      if (this.enableDNSVerification) {
        this.runDnsLookup(
          (data) => {
            this.onDNSLookupSuccess(data);
            // this.informMedusaAboutBanner();
          },
          (error) => {
            this.onDnsLookupError(error);
            // this.informMedusaAboutBanner();
          },
          {
            source: "control_panel_banner",
          }
        );
      }
    },
    setBrandDetails(otherInfo) {
      // set current brand and get its details
      brand.setDetails(otherInfo);
      this.brandDetails = brand.getDetails();
      this.brandLogo = this.brandDetails.logo;
    },
    getAllDomains() {
      return domainService.getDomainList().then(this.onDomainSuccess);
    },
    hideAppLoader() {
      this.isFetching = false;
      measureInitialAppLoad();
    },
    setMailPlanInfo(currentPlanDetails) {
      smartStorage.plan = currentPlanDetails
        ? currentPlanDetails.displayName
        : "";
    },
    fetchPaymentURLDetails(ac) {
      const billingActionType =
        this.hideBilling || this.view.openPaymentURLInDiffTab
          ? "billing"
          : null;
      const action = billingActionType || ac || "upgradePlan";
      return billingService
        .checkPaymentType(action)
        .then((data) => {
          let res = data;
          if (data.makePaymentUrl === "#") {
            this.paymentEnabled = false;
          } else {
            res = { ...data, action };
            this.$store.dispatch(
              "externalPayment",
              data.makePaymentUrl !== "inAppPayment" ? res : null
            );
          }
          return Promise.resolve(res);
        })
        .catch((error) => {
          console.error("Could not fetch payment details", error);
          this.$store.dispatch("externalPayment", null);
          return Promise.resolve(null);
        });
    },
    /**
     * Fetches the trial configuration from server.
     * @param addon {Object} - The addon for which configuration is to be fetched.
     */
    async fetchTrialConfig(addon) {
      const data = await this.$http.get("/panel/product/trial-config", {
        params: {
          product: addon.type,
        },
      });
      this.$store.dispatch("trials", {
        [addon.type]: data,
      });
    },
    /**
     * Fetches the trial config for all addons
     * @param addons {Array} - The array of addons obtained from GET /get-all-addons API for this account.
     */
    fetchAddonTrialConfigurations(addons) {
      for (const addonType of ADDONS_ON_TRIAL) {
        const addon = addons.find((a) => a.type === addonType);
        if (addon) {
          this.fetchTrialConfig(addon);
        }
      }
    },
    onDomainSuccess(data) {
      try {
        this.domainList = data.domains || [];
        if (this.isFromWebmail) {
          this.domainList = this.domainList.filter(
            (ele) => ele.name == this.domainName
          );
        }
        this.$root.domainList = this.domainList;
        // now currently plan will be decided from fetchPlans and not from domainList.
        const currentDomain = this.getCurrentDomainDetails();
        this.setLocale(currentDomain.locale);
        this.setCurrentDomainDetails(currentDomain);
        this.handleInternalRedirection();
      } catch (e) {
        this.logout();
      }
    },
    setCurrentDomainDetails(currentDomain) {
      const domainId = currentDomain?.id;
      const bundleId = currentDomain?.bundleId;
      smartStorage.setItem("domainID", domainId);
      smartStorage.setItem("bundleId", bundleId);
      this.$store.dispatch("domain/currentDomainDetails", currentDomain);
      this.$store.dispatch("domain/domainName", currentDomain.name);
      this.$store.dispatch("domain/domainID", currentDomain.id);
    },
    handleInternalRedirection() {
      // `section` is a route name
      const section = smartStorage.getItem("section");
      // `action` is an identifier to decide what to do in that section/route
      const action = hyphenToCamelCase(smartStorage.getItem("action"));
      const actionProps = smartStorage.getItem("actionProps");
      if (section) {
        const launchEmail = smartStorage.getItem("launchEmail");
        const params = getFilteredQueryParams(["section"]);

        /**
         * WARN: lets avoid adding query params for internal redirection
         * we can achieve same with `queryParamStore`
         * we don't need to expose/maintain an another action specific params in url
         */
        switch (action) {
          case ROUTE_ACTIONS["LAUNCH-EMAIL-CREATION"]:
          case ROUTE_ACTIONS.LAUNCH_EMAIL_CREATION:
            smartStorage.launchEmailCreation = launchEmail
              ? decodeURIComponent(launchEmail)
              : "true";
            break;
          case ROUTE_ACTIONS.RENEW:
            params.set(FLOW_TYPES.RENEW_BUNDLE, "true");
            break;
          case ROUTE_ACTIONS.RENEW_CARD_PAYMENT:
            queryParamStore.set(ROUTE_ACTIONS.RENEW_CARD_PAYMENT, true);
            break;
          case ROUTE_ACTIONS.LAUNCH_HIGHER_PLAN_UPGRADE:
            params.set(ROUTE_ACTIONS.LAUNCH_HIGHER_PLAN_UPGRADE, "true");
            break;
          case ROUTE_ACTIONS.REACTIVATE_SUBSCRIPTION:
            params.set(ROUTE_ACTIONS.REACTIVATE_SUBSCRIPTION, "true");
            break;
          case ROUTE_ACTIONS.SHOW_PLAN_FEATURE:
            params.set(ROUTE_ACTIONS_PROPS.FEATURE_KEY, actionProps);
            break;
          default:
            break;
        }

        this.$router.replace(
          `/${section}${params ? `?${params.toString()}` : ""}`
        );
        this.cleanUpRedirectionQueryParams();
      } else if (
        // when authenticated user trying to access non-authenticated route
        // standalone mode
        (/login/i.test(this.$route.name) ||
          // widget mode
          this.$route.meta.view === "widget") &&
        this.$route.name !== this.view.home
      ) {
        this.$router.replace({ name: this.view.home });
      }
    },
    cleanUpRedirectionQueryParams() {
      // smartStorage.removeItems(["section", "action", "launchEmail"]);
      smartStorage.removeItem("section");
      smartStorage.removeItem("action");
      smartStorage.removeItem("launchEmail");
      smartStorage.removeItem("actionProps");
    },
    setIsDiscountedPricingAndTrialInfoInPlans(
      storeKey,
      planList,
      currentBillingCycle,
      productPlanDetails
    ) {
      if (!planList.length || !currentBillingCycle || !productPlanDetails)
        return [];
      planList.forEach((plan) => {
        plan.planPricing.isDiscountedPricing =
          plan.planPricing?.[currentBillingCycle]?.firstBillingCyclePrice <
          plan.planPricing?.[currentBillingCycle]?.price;
      });
      const updatedPlans = addPlanTrialInfo(planList, productPlanDetails);
      this.$store.dispatch(storeKey, updatedPlans);
      return updatedPlans;
    },
    setCurrentPlanDetails(data, products) {
      const _hasMailSuiteProduct = hasMailSuiteProduct(products);
      const _hasSiteProduct = hasSiteProduct(products);
      const _hasDomainProduct = hasDomainProduct(products);

      const mailPlanDetails = _hasMailSuiteProduct
        ? this.getMailPlanDetails(data.mailSuiteDetails.planId)
        : null;
      const sitePlanDetails = _hasSiteProduct
        ? this.getSitePlanDetails(data.siteDetails.planId)
        : null;
      const domainPlanDetails = _hasDomainProduct
        ? this.getDomainPlanDetails(data.domainDetails.planId)
        : null;

      const currentDomain = this.getCurrentDomainDetails();
      const remainingDays = this.daysUntilExpiry;
      const domainDetails =
        _hasDomainProduct && domainPlanDetails
          ? formatDomainDetails(
              this.getDomainPlanDetails(
                domainPlanDetails.paidPlanId || domainPlanDetails.id
              ),
              domainPlanDetails,
              data.domainDetails,
              currentDomain
            )
          : null;

      const additionalPlanDetails = {
        isDomainPaid: domainPlanDetails?.isPaid,
        ...(_hasMailSuiteProduct
          ? {
              mailSuiteDetails: formatProductDetails(
                this.getMailPlanDetails(mailPlanDetails.paidPlanId),
                mailPlanDetails,
                data.mailSuiteDetails
              ),
            }
          : {}),
        ...(_hasSiteProduct
          ? {
              siteDetails: formatProductDetails(
                this.getSitePlanDetails(sitePlanDetails.paidPlanId),
                sitePlanDetails,
                data.siteDetails
              ),
            }
          : {}),
        ...(_hasDomainProduct
          ? {
              domainDetails,
            }
          : {}),
      };

      const currentPlanDetails = {
        billingCycle: "yearly", // default
        ...data,
        ...additionalPlanDetails,
        showBillingCycleSwitcher: !data.billingCycle,
        remainingDays,
      };

      this.$store.dispatch("currentPlanDetails", currentPlanDetails);
      this.$root.currentPlan = currentPlanDetails;
      this.setMailPlanInfo(currentPlanDetails?.mailSuiteDetails?.planDetails);
      this.setIsDiscountedPricingAndTrialInfoInPlans(
        "mail/plans",
        this.mailPlanList,
        currentPlanDetails.billingCycle,
        mailPlanDetails
      );
      this.setIsDiscountedPricingAndTrialInfoInPlans(
        "site/plans",
        this.sitePlanList,
        currentPlanDetails.billingCycle,
        sitePlanDetails
      );
      return currentPlanDetails;
    },
    fetchCurrentPlan() {
      return billingService.getCurrentPlan().catch((e) => {
        Logger.error(e);
        if (!getProductsFromCurrentPlan(this.currentPlan)?.length) {
          this.forceLogout(e);
        }
        return Promise.reject(e);
      });
    },
    // kept this `cb` is for existing/old code implementation
    syncCurrentPlan(cb) {
      return this.fetchCurrentPlan()
        .then((data) => {
          const products = getProductsFromCurrentPlan(data);
          const updatedData = this.setCurrentPlanDetails(data, products);
          cb?.(updatedData);
          return updatedData;
        })
        .catch(Logger.error);
    },
    fetchAddons() {
      return billingService.getAllAddons().then((data) => {
        // success
        this.addons = data.addons || [];
        return this.fetchAddonTrialConfigurations(this.addons);
      });
    },
    async fetchCardDetails() {
      try {
        return await billingService.getCardDetails();
      } catch {
        // don't break flow
        return Promise.resolve(null);
      }
    },
    redirectToLogin() {
      clearAuthSession();
      this.authenticated = false;
      this.$router.push({ name: "Login" });
    },
    async syncCurrentPlanAndProducts(cb) {
      try {
        await this.syncAllProductPlans();
        await this.syncCurrentPlan();
        cb?.();
      } catch (e) {
        Logger.error("Failed to sync current plan and products", e);
      }
    },
    // Events on EventBus
    destroyFetchListeners() {
      eventBus.$off("go-to-login", this.redirectToLogin);
      eventBus.$off("fetchAddons", this.fetchAddons);
      eventBus.$off("fetchCardDetails", this.fetchCardDetails);
      eventBus.$off("pageLoaded", this.pageLoaded);
      eventBus.$off("fetchCurrentPlan", this.syncCurrentPlan);
      eventBus.$off(
        "syncCurrentPlanAndProducts",
        this.syncCurrentPlanAndProducts
      );
      eventBus.$off("syncAllProductPlans", this.syncAllProductPlans);
      eventBus.$off("refetch-email-accounts", this.fetchEmailAccounts);
      eventBus.$off(
        "handleEntriSetupRedirection",
        this.handleEntriSetupRedirection
      );
    },
    setupListeners() {
      if (isAppLoadedInsideIframe()) {
        window.addEventListener("message", this.onIframeDimensionChange);
      }
      eventBus.$on("go-to-login", this.redirectToLogin);
      eventBus.$on("fetchAddons", this.fetchAddons);
      eventBus.$on("pageLoaded", this.pageLoaded);
      eventBus.$on("fetchCardDetails", this.fetchCardDetails);
      eventBus.$on("fetchCurrentPlan", this.syncCurrentPlan);
      eventBus.$on("syncAllProductPlans", this.syncAllProductPlans);
      eventBus.$on(
        "syncCurrentPlanAndProducts",
        this.syncCurrentPlanAndProducts
      );
      eventBus.$on("refetch-email-accounts", this.fetchEmailAccounts);
      eventBus.$on(
        "handleEntriSetupRedirection",
        this.handleEntriSetupRedirection
      );
    },
    fetchControlPanelSource() {
      // Fetch Source from which Control panel was loaded
      const availableSources = ["isFromWebmail", "isFromNeo"];
      const currentSource = availableSources.find(
        (sourceHook) => this.$route.query[sourceHook]
      );
      if (currentSource) {
        this[currentSource] = true;
      }
    },
    validateTokenAndInitCP() {
      if (smartStorage.getItem("auth")) {
        this.authenticateExternalToken();
      } else if (smartStorage.getItem("authToken")) {
        this.initCP();
      } else {
        this.authenticated = false;
      }
    },
  },
  mounted() {
    this.shouldShowResponsiveLayout = shouldShowResponsiveCP();

    if (isAppLoadedInsideIframe()) {
      observedElementDetails = this.observeChangesInDOM(
        document.getElementById("app")
      );
    }
    // this condition gets exce when user is on mobile (and not in iframe) and user is not logged-in
    // and based on showResponsiveLayout flag we show/hide mobile version on CP
    // ideally isInitCPStepsDone gets assigned once the all API fetching is done
    // but this is special case due to neo (where we support mobile version of CP)
    // in titan we show error screen for mobile
    else if (!this.authenticated && this.isMobile) {
      this.hideAppLoader();
    }
  },
  beforeCreate() {
    const domain = smartStorage.getItem("domain");
    this.$store.dispatch("domain/domainName", domain);
  },
  created() {
    this.setViewAndTheme();
    this.setupListeners();
    this.fetchControlPanelSource();
    initGtm();
    this.validateTokenAndInitCP();
  },
  beforeDestroy() {
    this.destroyFetchListeners();
    window.removeEventListener("message", this.onIframeDimensionChange);
    if (observedElementDetails.observer)
      observedElementDetails.observer.disconnect();
  },
  watch: {
    /**
     * Some partners need to know if the number of active/purchased/limit (available) mailboxes on this domain.
     * This is a way to notify them.
     * @param {EmailAccounts} emailAccounts - Consists of the `active`, `limit` and `purchased` mailboxes.
     */
    "currentPlan.noOfAccounts": function (emailAccounts, oldEmailAccounts) {
      if (
        hasMailSuiteProduct(this.products) &&
        (emailAccounts?.active !== oldEmailAccounts?.active ||
          emailAccounts?.purchased !== oldEmailAccounts?.purchased ||
          emailAccounts?.limit !== oldEmailAccounts?.limit)
      ) {
        sendPostMessageToParentWindow(EMAIL_ACCOUNTS_CHANGED, emailAccounts);
      }
    },
    domainConnectData() {
      if (!this.siteVerifiedAndConnected || !this.domainVerifiedAndConnected) {
        this.fetchEntriDetailsIfMissing();
      }
    },
  },
};
</script>

<style lang="scss">
html {
  background: var(--primaryBgCol) no-repeat;
}
div,
p,
span,
th,
tr,
input,
li,
p,
h1,
h2,
h3,
h4 {
  color: var(--primaryTextCol);
}
input {
  background: var(--tabBgCol);
  color: var(--primaryTextCol);
}
#app {
  font-family: var(--app-font-type);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background: var(--primaryBgCol);
}
.link {
  color: var(--linkBtnCol);
  display: inline-block;
  cursor: pointer;

  &:hover {
    color: var(--linkBtnCol);
    text-decoration: underline;
  }
}

.modal-open {
  overflow: hidden;
}
.warning-validation,
.error-validation {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 68px;
  left: 0;
  background: var(--warningBgCol);
  padding: 9px 7px;
  color: var(--genericTextCol);
  z-index: 3;
  font-size: 14px;

  i {
    display: inline-block;
    width: 18px;
    height: 15px;
    background: url("./assets/warning-icon.svg") center no-repeat;
    background-size: contain;
    vertical-align: -2px;
    margin-right: 10px;
  }

  .emoji {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    display: inline-block;
    background: url("./assets/celebration.svg") center no-repeat;
    transform: scale(1.2); // necessary else icons looks very small
    vertical-align: text-bottom;
  }

  span {
    font-weight: 600;
    color: var(--genericTextCol);
    & + div {
      display: inline-block;
    }
  }

  .button {
    padding: 7px 18px;
  }

  .button:hover {
    text-decoration: none;
  }
}
.error-validation {
  border: solid 1px var(--errorSecBordCol);
  background-color: var(--errorSecTextCol);
  i {
    background: url("./assets/warning-icon-red.svg") center no-repeat;
  }

  &.secondary {
    border-color: var(--secondaryErrorBannerBorder);
    background-color: var(--secondaryErrorBannerBg);

    i {
      width: 16px;
      height: 16px;
      background: url("./assets/blue-info-icon.svg") center no-repeat;
      margin-right: 8px;
    }
  }
}
.banner-for-widget div {
  position: static;
}
.increase-padding-container {
  padding-top: 105px !important;
}

.active-now {
  margin-left: 10px !important;
}

.button {
  color: var(--primaryBtnTextCol);
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  background-color: var(--primaryBtnCol);
  padding: 10px 18px;
  border: 0;
  line-height: 150%;
  cursor: pointer;

  &.invalid {
    opacity: 0.5;
    cursor: default;
  }
  &.show-spinner {
    @include button-loader();
  }
  &.show-disabled-spinner {
    @include disabled-button-loader();
  }

  &:focus {
    outline: 0;
  }

  &.btn-danger,
  &.btn-danger:hover,
  &.btn-danger:focus,
  &.btn-danger:active {
    background-color: var(--errorPrimaryTextCol) !important;
    box-shadow: none !important;
    border: none !important;
  }

  &.button__gray {
    background: var(--lineSepaCol);
    border: 1px solid var(--lineSepaCol);
    color: var(--primaryTextCol);
    font-weight: normal;
    &:hover {
      background: var(--lineSepaCol);
    }
  }

  &.button__line-blue {
    background: transparent;
    border: 1px solid var(--transparent-btn);
    color: var(--transparent-btn);

    &:hover {
      background: transparent;
    }
  }

  &.button__transparent {
    background: transparent;
    border: 0;
    padding: 10px 8px;
  }

  &:nth-child(n + 2) {
    margin-left: 16px;
  }

  &:first-of-type {
    margin: 0;
  }
}
.widgetView {
  margin: 30px 0;
  width: 100%;
}
.widgetViewContainer {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .help-link-icon {
    width: 66px;
    height: 23px;
    background: url("./assets/help-grey.svg");
  }
  .rightAlignIcon {
    position: absolute;
    right: 0;
  }
}
.powered-by-titan {
  text-align: center;
  margin-top: 30px;
}
.view {
  margin-top: 50px;
  padding: 50px;
  margin-left: 280px;
  background: var(--primaryBgCol);
  min-height: calc(100vh - 68px);
  width: calc(100% - 280px);
}

.card-container {
  border-radius: 5px;
  background-color: var(--tabBgCol);
  padding: 24px;
  border: 1px solid var(--lineSepaCol);
  margin-top: 30px;
  header {
    font-size: 16px;
  }
}

h4.view-heading {
  font-size: 20px;
  font-weight: 600;
}

.card-heading {
  font-size: 16px;
  font-weight: 600;
}

.card-subheading {
  color: var(--secondaryTextCol);
}

/*commons*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
}

.input-wrapper {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.input-addon-wrapper {
  position: relative;

  .password-hidden,
  .password-shown {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .input {
    padding: 9px 55% 9px 15px;
  }
}

.color-grey {
  color: var(--textBtnCol);
}

.color-blue {
  color: var(--primaryBtnCol);
}

.addon {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
  background: var(--readOnlyTxtBgCol);
  display: inline-block;
  padding: 9px;
  border-radius: 0 4px 4px 0;
  border-left: 1px solid var(--disableCol);
  line-height: normal;
  width: 50%;
  height: calc(100% - 3px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: var(--primaryTextCol);
}

.input {
  border-radius: 4px;
  background-color: var(--tabBgCol);
  border: 1px solid var(--inputBorderCol);
  padding: 9px 15px;
  line-height: 20px;
  width: 100%;

  &[readonly="readonly"] {
    color: var(--secondaryTextCol);
    background-color: var(--readOnlyTxtBgCol);
  }

  &.has-error {
    border: 1px solid var(--errorPrimaryTextCol);
  }

  &:focus {
    border: 1px solid var(--inputFocusBorderCol);
    outline: 0;
  }
}

.error {
  color: var(--errorPrimaryTextCol);
  font-size: 12px;
  letter-spacing: normal;
}

.input.password.password-hidden {
  background: url("/assets/password-toggle-on.svg") center right no-repeat /
    contain;
}

.input.password.password-shown {
  background: url("/assets/password-toggle-off.svg");
}

.input::placeholder {
  color: var(--inputBorderCol);
}

p {
  line-height: 18px;
  color: var(--primaryTextCol);
  font-size: 14px;
}

div.toasted-container {
  z-index: 10;
  .toasted.toasted-primary {
    min-width: 300px;
    border-radius: 4px;
    background: var(--primaryTextCol);
    color: var(--tabBgCol);
    padding: 19px 24px;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.33;

    .ripple.action {
      text-decoration: none;
      color: var(--tabBgCol);
      font-size: 20px;
      padding: 0;
      margin: 0 0 0 20px;
      font-weight: normal;

      &:hover {
        color: var(--tabBgCol);
      }
    }
  }
}

.v-context.overflow-context-menu {
  background-color: var(--tabBgCol) !important;
  box-shadow: 0 0 8px -1px var(--boxShadowCol7) !important;
  width: 215px !important;
  right: -20px !important;
  bottom: -27px !important;
  position: absolute !important;
  ul {
    li {
      padding: 8px 24px !important;
      font-size: 14px;
      font-weight: 400;
      color: var(--primaryTextCol);
      text-align: left;

      &:hover {
        background: var(--readOnlyTxtBgCol) !important;
        color: var(--primaryTextCol) !important;
      }

      &:last-of-type,
      &:first-of-type {
        margin: 0 !important;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.without-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
.without-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar-thumb {
  border-radius: 30px;
  -webkit-box-shadow: inset 0 0 6px var(--boxShadowCol1);
}

.vue-tooltip {
  background: var(--primaryTextCol) !important;
  font-size: 12px !important;

  .tooltip-arrow {
    border-top-color: var(--primaryTextCol) !important;
  }
}

.search-container {
  text-align: right;

  .search-wrapper {
    position: relative;
    margin-right: 16px;

    .input {
      max-width: 290px;
      background: url("./assets/search-small.svg") no-repeat no-repeat 10px
        center/16px;
      padding-left: 30px;
    }

    .clear-search {
      width: 15px;
      height: 18px;
      background: url("./assets/cross.svg") center no-repeat / contain;
      display: inline-block;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

/*Tags input CSS*/
.vue-tags-input {
  .ti-input {
    border-radius: 4px;
    background-color: var(--tabBgCol);
    border: 1px solid var(--inputBorderCol) !important;
    padding: 8px 15px !important;
    line-height: 20px;
    width: var(--inputBoxWidth);
    max-height: 250px;
    overflow: auto;

    &:focus {
      border: 1px solid var(--inputFocusBorderCol) !important;
      outline: 0 !important;
    }

    .ti-tag {
      user-select: none;
      margin: 4px !important;

      &:first-of-type {
        margin-left: 0 !important;
      }

      &:last-of-type {
        margin-right: 8px !important;
      }

      &.input-tag {
        background: var(--tabBgCol);
        color: var(--primaryTextCol);
        border: 1px solid var(--lineSepaCol);
        border-radius: 11px;
        padding: 4px 8px;
        font-weight: 500;
        font-size: 14px;
        /*margin-bottom: 3px !important;*/
        /*margin-top: 3px !important;*/
        margin: 3px 8px 3px 0 !important;
        word-break: break-all;

        &.ti-deletion-mark {
          background: var(--readOnlyTxtBgCol) !important;
        }
      }

      .ti-actions {
        margin-left: 8px !important;
        font-size: 12px !important;
        color: var(--secondaryTextCol) !important;
      }
    }

    .ti-new-tag-input-wrapper {
      padding: 0 !important;
      margin: 0 !important;
      background: var(--tabBgCol);

      input {
        font-size: 14px;
        line-height: 22px;
        caret-color: var(--primaryBtnCol);
        margin: 3px;
      }
    }
  }

  .ti-autocomplete {
    border-radius: 4px;
    box-shadow: 0 0 2px 2px var(--boxShadowCol2);
    background-color: var(--tabBgCol);
    border: 0 !important;
    margin-bottom: 30px;
    color: var(--primaryTextCol) !important;
    max-height: 200px;
    overflow: auto;

    .ti-item {
      padding: 9px 15px !important;
      background-color: var(--tabBgCol) !important;
      &.ti-selected-item {
        color: var(--primaryTextCol) !important;
      }

      & > div {
        padding: 0 !important;
      }
      &:hover {
        background-color: var(--dropdownItemBgCol) !important;
        div {
          color: var(--genericTextCol) !important;
        }
      }
    }

    .no-forwarder-email {
      padding: 12px 14px;
      background: var(--readOnlyTxtBgCol);

      p {
        margin: 0;
      }

      small {
        font-size: 12px;
      }
    }
  }

  &.ti-focus {
    .ti-input {
      border: 1px solid var(--inputFocusBorderCol) !important;
    }
  }
}
.main {
  display: flex;
}

.non-iframe-widget {
  padding: 40px;
}

.modal-overlay.iframe-mode {
  background: unset;
  padding-left: 0;
  position: unset;
  text-align: left;
  justify-content: flex-start;
  .modal-body-container {
    background: unset;
    text-align: left;
    padding-left: 0;
    box-shadow: unset;
    background: unset;
  }
}
.btn:hover {
  color: unset;
}

.no-scroll {
  overflow: hidden;
}

.font-w-500 {
  font-weight: 500;
}

@import "styles/variables";
@media screen and (max-width: $responsive-breakpoint) {
  .main {
    justify-content: center;
    display: block;
  }

  .view {
    width: 100%;
    padding: 0 16px;
    margin: 0;
  }
}
</style>
