<template>
  <Loader />
</template>

<script>
import brand from "../../helpers/brand";
import { hardRedirect } from "../../helpers";
import Loader from "../Utility/Loader";
import { measurePagePerformance } from "@/analytics/performance/pageTimings";
import { sentryCaptureException } from "@/helpers/sentry";
import authService from "@/services/auth/auth.service";
import { AUTH_STATE } from "@/helpers/const";

/**
 * component responsible for auto login via webmail
 * required query params
 * - token: webmail token
 * - email: mailbox email
 * other query params
 * - redirect: redirection route if mx and spf not set
 * - section: after login where to redirect
 * - action: after login what action to take (check App.vue for action and section)
 * - locale: lang
 */
export default {
  name: "LoginFromWebmail",
  components: { Loader },
  data() {
    return {
      redirectRoute: "",
      isTitanMail: null,
      brandDetails: {},
      queryParams: {},
    };
  },
  mounted() {
    measurePagePerformance();
  },
  created() {
    if (this.authState === AUTH_STATE.AUTH) return;

    this.setBrandDetails();
    const webmailToken = this.$route.query.token;
    const email = this.$route.query.email;
    this.queryParams = this.$route.query;
    this.redirectRoute = this.$route.query.redirect
      ? this.$route.query.redirect
      : "";
    this.handleWebmailLogin(webmailToken, email);
    if (this.$route.query.locale) {
      smartStorage.lang = this.$route.query.locale;
    }
    let query = Object.assign({}, this.$route.query);
    query = {};
    this.$router.replace({ query });
  },
  props: {
    setOfacParams: Function,
    authState: String,
  },
  methods: {
    setBrandDetails() {
      this.isTitanMail =
        this.isTitanMail === null
          ? brand.deriveBrandFromURL() === brand.NAMES.TITAN
          : this.isTitanMail;
      this.brandDetails = brand.getDetails();
    },
    handleWebmailLogin(webmailToken, email) {
      return authService
        .loginWithWebmail(email, webmailToken)
        .then(this.onSuccess)
        .catch(this.onError);
    },
    onSuccess(data) {
      if (data) {
        // get partner info and check whether we can call dnslookup
        this.getPartnerInfo()
          .then(this.onSuccessPartnerFetch)
          .catch(this.onError);
      }
    },
    onSuccessPartnerFetch(data) {
      if (data.flags && data.flags.enableDNSVerification) {
        this.runDnsLookup(this.onDNSLookupSuccess, this.onError);
      } else {
        hardRedirect("/?isFromWebmail=true");
      }
    },
    onError(err) {
      if (err?.data?.code === "OFAC_SANCTIONED_COUNTRY") {
        this.setOfacParams({
          isOfacFlag: true,
          ofacDocumentation: this.brandDetails.ofacDocumentation,
          supportEmail: this.brandDetails.supportEmail,
          brandLogo: this.brandDetails.logo,
        });
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            {
              eventName: "ofac_login_denied",
              ofac_country: err.data.attrs.countryName,
              // is_first_login
            },
            smartStorage.getItem("domain"),
            "flock_order"
          )
        );
      } else {
        hardRedirect("/");
        console.error("failed to login from webmail", err);
        sentryCaptureException(
          err,
          { loginFailure: "webmailLogin" },
          {
            query: JSON.stringify({
              email: this.queryParams?.email,
              redirect: this.queryParams?.redirect,
            }),
          }
        );
      }
    },
    onDNSLookupSuccess(data) {
      if (!data.mxVerified || !data.spfVerified) {
        hardRedirect("/" + this.redirectRoute + "?isFromWebmail=true");
      } else {
        hardRedirect("/redirect-handler?isFromWebmail=true");
      }
    },
  },
};
</script>
