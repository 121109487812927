<script>
import { BTooltip } from "bootstrap-vue";
import { mapGetters } from "vuex";

import MailLinks from "../Utility/MailLinks";
import EmailCreatedSuccess from "./EmailCreatedSuccess.vue";
import SendDetails from "../Utility/SendDetails";
import PasswordActions from "../Utility/PasswordActions";
import GeneratePassword from "../Utility/GeneratePassword";
import { isNeoBrand } from "@/helpers";
import { toGB, isSizeInGB } from "../../helpers";
import brand from "../../helpers/brand";
import { REGX, EMAIL_VALIDITY_ERROR_CODES } from "../../helpers/const";
import viewTypes from "../../views";
import eventBus from "@/helpers/event-bus";
import {
  emailAvailabilityStatus,
  getUserNameFromEmail,
  validatePassword,
} from "@/helpers/email";
import emailService from "@/services/email/email.service";

export default {
  name: "CreateEmail",
  components: {
    MailLinks,
    SendDetails,
    PasswordActions,
    GeneratePassword,
    BTooltip,
    EmailCreatedSuccess,
  },
  props: {
    hasAdmin: Boolean,
    view: Object,
    emailToDeleteDetails: {
      type: Object,
      default: () => ({
        accountId: false,
        emailId: "",
      }),
    },
  },
  data() {
    const brandDetails = brand.getDetails();
    return {
      user:
        smartStorage.getItem("launchEmailCreation") &&
        smartStorage.getItem("launchEmailCreation") !== "true"
          ? smartStorage.getItem("launchEmailCreation").trim()
          : "",
      email: "",
      altEmail: "",
      password: "",
      recipient: "",
      serverError: "",
      isAdmin: !this.hasAdmin,
      autoGenerate: false,
      showPassword: false,
      showAltEmailError: "",
      success: false,
      navigator: navigator,
      storageLimit: "",
      memorySizeUnit: "MB",
      theme: document.documentElement.getAttribute("theme"),
      hideBrandInfoInTooltip: false,
      showPasswordError: false,
      brandDetails,
      preventAccountCreation: false,
      emailAvailabilityError: "",
      deletedEmail: this.emailToDeleteDetails?.emailId, // Adding this to data instead of using directly from props because after the email is deleted and created successfully, the user object will get updated and won't have the info of the deleted email. We need to show the email that was deleted in a popup.
    };
  },
  computed: {
    ...mapGetters({
      domainName: "domain/domainName",
      currentPlanMailDetails: "mail/currentProductDetails",
    }),
    isEmailCreationDisabled() {
      return (
        this.user.length < 1 || this.showPasswordError || !this.password.length
      );
    },
  },
  watch: {
    password(newPassword) {
      if (this.autoGenerate) {
        this.showPasswordError = false;
        return;
      }
      this.validatePassword(newPassword);
    },
  },
  methods: {
    validatePassword(password) {
      const {
        isSmallPassword,
        isLargePassword,
        isPatternMatching,
      } = validatePassword(password);
      if (isSmallPassword || isLargePassword || !isPatternMatching) {
        this.showPasswordError = true;
      } else {
        this.showPasswordError = false;
      }
    },
    close() {
      this.user = "";
      this.email = "";
      this.altEmail = "";
      this.password = "";
      this.recipient = "";
      this.serverError = "";
      this.autoGenerate = false;
      this.showPassword = false;
      this.success = false;
      this.$emit("close");
      smartStorage.removeItem("launchEmailCreation");
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    async createAccount() {
      if (this.preventEmailCreation) return false;
      if (this.altEmail && !REGX.email.test(this.altEmail)) {
        this.showAltEmailError = this.$t(
          "emailAccounts.createEmail.invalidEmail"
        );
        return false;
      }

      if (this.user.length > 0) {
        this.email = this.user + "@" + smartStorage.getItem("domain");
        const alternateEmail = this.altEmail
          ? { alternateEmail: this.altEmail }
          : {};
        this.preloadImage();
        if (!(await this.checkEmailAvailability(this.email))) {
          return;
        }
        this.preventEmailCreation = true;

        // If emailToDeleteDetails.accountId is a number (i.e., a valid ID), it indicates that the mailbox with this ID should be deleted before a new one is created.
        if (this.emailToDeleteDetails?.accountId) {
          return this.$http
            .post("panel/account/email/delete-and-create", {
              domainName: this.domainName,
              oldAccountId: this.emailToDeleteDetails.accountId,
              email: this.email,
              password: this.password,
              ...alternateEmail,
            })
            .then(() => {
              this.onCreateEmailSuccess();
              this.sendMailAccountDeletedEvent();
            })
            .catch(this.onCreateEmailError);
        }

        // If emailToDeleteDetails.accountId is false, no mailbox will be deleted when creating a new one.
        return emailService
          .createEmail({
            email: this.email,
            password: this.password,
            domainName: this.domainName,
            isAdmin: this.isAdmin,
            ...alternateEmail,
          })
          .then(this.onCreateEmailSuccess)
          .catch(this.onCreateEmailError);
      }
    },

    async checkEmailAvailability(email) {
      if (!isNeoBrand()) {
        return true;
      }
      const { isAvailable, errorCode, values } = await emailAvailabilityStatus(
        email
      );
      if (isAvailable) return true;
      this.serverError = this.$t(
        `emailAccounts.createEmail.emailAvailabilityErrors.${errorCode}`,
        values
      );
      this.emailUnavailabilityErrorMedusaEvent(email, errorCode);
      return false;
    },

    async emailUnavailabilityErrorMedusaEvent(email, errorCode) {
      if (errorCode !== EMAIL_VALIDITY_ERROR_CODES.TOP_BRAND) return;
      var eventObj = {
        eventName: "mail_account_creation_failed",
        email,
        error: "Brand name",
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(eventObj, email, "flock_account")
      );
    },

    sendMailAccountDeletedEvent() {
      if (this.emailToDeleteDetails?.accountId) {
        const eventObj = {
          eventName: "mail_account_deleted",
          type: "pop",
          last_account: true,
        };
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            eventObj,
            this.deletedEmail,
            "flock_account"
          )
        );
      }
    },

    onCreateEmailSuccess() {
      this.preventEmailCreation = false;
      this.success = true;
      smartStorage.removeItem("launchEmailCreation");
      this.$parent.getEmailList();
      eventBus.$emit("fetchCurrentPlan");
      eventBus.$emit("refetch-email-accounts");
      const eventObj = {
        eventName: "mail_account_created",
        type: "pop",
        source_hook: "Regular account creation flow",
        first_account_auto_created: this.emailToDeleteDetails?.accountId
          ? true
          : false,
      };
      this.medusaEvents.send(
        this.medusaEvents.createEventObj(eventObj, this.email, "flock_account")
      );

      if (this.altEmail) {
        this.medusaEvents.send(
          this.medusaEvents.createEventObj(
            {
              eventName: "account_atlernate_email_filled",
              alternate_email: this.altEmail,
            },
            this.email,
            "flock_account"
          )
        );
      }
    },
    onCreateEmailError(response) {
      this.preventEmailCreation = false;
      const reasonCode = response?.data?.attrs?.unAvailabilityReason;
      if (reasonCode) {
        this.serverError = this.$t(
          `emailAccounts.createEmail.emailAvailabilityErrors.${reasonCode}`,
          { username: getUserNameFromEmail(this.email) }
        );
      }
      if (response && response.data && response.data.desc) {
        this.serverError = this.serverError || response.data.desc;
      }
      if (this.serverError) {
        smartStorage.removeItem("launchEmailCreation");
      }
    },
    preloadImage() {
      // eslint-disable-next-line no-undef
      const logo = this.$options.filters.themeImage("email-creation-success");
      let img = new Image();
      img.onload = function () {
        console.log("Loaded::::");
      };
      img.onerror = (err) => {
        console.log("Failed to download", err);
      };
      img.src = logo;
    },
  },
  created() {
    this.hideBrandInfoInTooltip =
      viewTypes[document.documentElement.getAttribute("view")][
        "hideBrandInfoInTooltip"
      ];
    this.storageLimit = this.currentPlanMailDetails.planDetails?.features
      ?.length
      ? this.currentPlanMailDetails.planDetails?.features?.find(
          (f) => f.type === "storage"
        ).attrs.quota_in_mb
      : "";
    if (isSizeInGB(this.storageLimit)) {
      this.storageLimit = toGB(this.storageLimit);
      this.memorySizeUnit = "GB";
    }
  },
};
</script>
<template>
  <transition name="modal-fade">
    <div class="modal-overlay create-email">
      <div
        :class="[
          'modal-body-container',
          {
            modalPage: view.showModalAsPage,
          },
        ]"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <button
          type="button"
          class="btn-close"
          @click="close"
          aria-label="Close modal"
          v-if="!view.showModalAsPage && success"
        >
          &times;
        </button>
        <div class="modal-content-wrapper">
          <widget-breadcrumb-nav
            modalName="create-new-email-account"
            :isModal="true"
            v-if="view.showModalAsPage"
            @onNavClick="close"
          />
          <EmailCreatedSuccess
            :deletedEmail="
              emailToDeleteDetails?.accountId && deletedEmail
                ? deletedEmail
                : ''
            "
            :email="email"
            :password="password"
            :close="close"
            v-if="success"
          />
          <div v-else>
            <h4 class="create-email-header">
              {{ $t("emailAccounts.createEmail.heading") }}
            </h4>

            <!-- email -->
            <div class="input-wrapper email-field">
              <label>{{ $t("emailAccounts.createEmail.emailLabel") }}</label>
              <div class="input-addon-wrapper">
                <input
                  type="text"
                  class="input"
                  placeholder="e.g John"
                  v-model.trim="user"
                  @keyup="serverError = ''"
                  autocomplete="new-password"
                />
                <span class="addon" :title="domainName">@{{ domainName }}</span>
              </div>
              <div class="error email-error">
                {{ serverError }}
              </div>
              <span class="checkbox">
                <input
                  type="checkbox"
                  id="checkboxContent"
                  name="checkboxContent"
                  value="checkboxContent"
                  v-model="isAdmin"
                  :disabled="emailToDeleteDetails?.accountId"
                  :class="{ disabledCheckbox: emailToDeleteDetails?.accountId }"
                />
                <label for="checkboxContent">{{
                  $t("emailAccounts.createEmail.adminCheckbox")
                }}</label>
              </span>
              <i class="info" id="infoI" />
              <b-tooltip
                boundary="viewport"
                custom-class="custom-tooltip"
                target="infoI"
                placement="rightbottom"
              >
                <div
                  class="text-left"
                  v-html="
                    hideBrandInfoInTooltip
                      ? $t('emailAccounts.createEmail.widgetAdminInfo')
                      : $t('emailAccounts.createEmail.adminInfo', [
                          brandDetails.name,
                        ])
                  "
                />
              </b-tooltip>
            </div>

            <!-- password -->
            <div class="input-wrapper">
              <label>{{ $t("emailAccounts.createEmail.passwordLabel") }}</label>
              <div class="input-addon-wrapper">
                <input
                  :type="showPassword ? 'text' : 'password'"
                  class="input password-input"
                  :class="{ 'has-error': showPasswordError }"
                  v-bind:placeholder="$t('placeholders.password')"
                  v-model="password"
                  :readonly="autoGenerate"
                  autocomplete="new-password"
                />
                <span
                  :class="showPassword ? 'password-hidden' : 'password-shown'"
                  @click="togglePassword"
                  v-show="password.length > 0"
                ></span>
              </div>
              <div class="error" v-if="showPasswordError">
                {{ $t("emailAccounts.createEmail.passwordError") }}
              </div>
            </div>
            <generate-password
              :password="password"
              :view="view"
            ></generate-password>
            <!-- alt email -->
            <div class="input-wrapper alt-email-field">
              <label>{{ $t("emailAccounts.createEmail.altEmailLabel") }}</label>
              <i class="info" id="infoAltEmail" />
              <b-tooltip
                boundary="viewport"
                custom-class="custom-tooltip"
                target="infoAltEmail"
                placement="rightbottom"
              >
                <div class="text-left">
                  {{ $t("emailAccounts.createEmail.altEmailTooltip") }}
                </div>
              </b-tooltip>
              <div class="input-addon-wrapper">
                <input
                  type="text"
                  v-model.trim="altEmail"
                  :placeholder="
                    $t('emailAccounts.createEmail.altEmailPlacehoder')
                  "
                  @focus="showAltEmailError = ''"
                  :class="{
                    'has-error': !!showAltEmailError,
                  }"
                />
              </div>
              <div class="error" v-if="showAltEmailError">
                {{ showAltEmailError }}
              </div>
            </div>
            <p class="grey-font font-12px">
              {{ $t("emailAccounts.createEmail.share") }}
            </p>

            <!-- submit -->
            <div
              class="input-wrapper"
              :class="storageLimit ? 'btn-for-storage' : ''"
            >
              <button
                class="button"
                :class="{
                  invalid: isEmailCreationDisabled,
                }"
                @click="createAccount"
                :disabled="isEmailCreationDisabled"
              >
                {{ $t("emailAccounts.createEmail.createNew") }}
              </button>
              <button
                type="button"
                :class="['btn-close', { 'cancel-btn': view.showModalAsPage }]"
                @click="close"
                aria-label="Close modal"
                v-html="
                  !view.showModalAsPage || success
                    ? '&times'
                    : $t('utility.cancel')
                "
              ></button>
            </div>
            <div
              :class="[
                'input-wrapper',
                'storage-limit-option',
                {
                  'widget-storage-limit-info': view.showModalAsPage,
                },
              ]"
              v-if="storageLimit"
            >
              <img :src="'storage' | themeImage" />
              <p
                v-html="
                  $t('emailAccounts.createEmail.storageLimit', [
                    storageLimit,
                    memorySizeUnit,
                  ])
                "
                class=""
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.modal-overlay {
  position: fixed;
  top: var(--modalOverlayTop);
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--boxShadowCol5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.create-email {
  .checkbox {
    // $params are in the given order
    // margin-top , margin-right, margin-bottom, margin-left
    // padding-top , padding-right, padding-bottom, padding-left
    //width , height
    @include custom-checkbox(8, 12, 0, 0, 2, 2, 2, 2, 16, 16);
    label {
      font-weight: normal;
    }
  }
  .checkbox + i.info {
    vertical-align: 0;
    transform: translateY(-1px);
  }
  input[type="checkbox"].disabledCheckbox + label {
    cursor: not-allowed;
  }
  .note {
    border-radius: 5px;
    border: solid 1px var(--noteBordCol);
    padding: 16px 16px 16px 40px;
    box-sizing: border-box;
    margin: 32px 0;
    font-size: 14px;
    font-weight: normal;
    color: var(--primaryTextCol);
    background-color: var(--warningBgCol);
    background-image: url("../../assets/error-state-2.svg");
    background-position: 12px 14px;
    background-repeat: no-repeat;
    width: auto;
    p {
      margin-bottom: 12px;
      color: var(--genericTextCol);
    }

    a {
      width: auto;
      color: var(--linkBtnCol);
    }
  }
  .widget-note {
    margin: -20px 0 24px 0;
  }
}

.alt-email-field {
  margin-bottom: 4px;
  input {
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--inputBorderCol);
    background-color: var(--tabBgCol);
    display: block;
    padding: 8px 15px;
    margin: 0 auto;

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }

    &.has-error {
      border-color: var(--errorPrimaryTextCol);
    }

    &:focus {
      border-color: var(--inputFocusBorderCol);
    }
  }
}

i.info {
  background-color: var(--tabBgCol);
  display: inline-block;
  width: 12px;
  height: 20px; // ideally it should be 12 but just to adjust carrot position keeping 20
  background: url("../../assets/info-i.svg") no-repeat bottom;
  background-size: contain;
  vertical-align: -2px;
  margin-left: 5px;
}

.email-field {
  label {
    display: inline-block;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.29;
    color: var(--primaryTextCol);
    input {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 2px;
      vertical-align: -1px;
    }
  }
}
.input-addon-wrapper {
  width: var(--inputBoxWidth);
  input {
    &:focus {
      border: 1px solid var(--inputFocusBorderCol);
    }
  }
  .password-input {
    padding-right: 15px; // increasing padding for placeholder
  }
}

.custom-tooltip {
  color: var(--tabBgCol);

  & > div {
    padding: 16px;
    font-size: 14px;
    font-weight: normal;
  }
}

.btn-for-storage {
  margin-top: 32px;
  padding-bottom: 40px;
  button {
    background-color: var(--secondaryBtnCol);
    font-size: var(--modalBtnTextSize);
  }
  .btn-close {
    font-size: 28px;
  }
}
.storage-limit-option {
  position: absolute;
  bottom: 0;
  padding: 18px 40px 4px;
  border: 1px solid var(--textSepaCol);
  left: 0;
  width: 100%;
  p {
    display: inline-block;
    margin-left: 7px;
    font-size: 12px;
  }
  img {
    width: 18px;
    height: 18px;
  }
}

.widget-storage-limit-info {
  position: relative !important;
  border: none;
  border-top: 1px solid var(--lineSepaCol);
  top: -9px;
  padding-left: 0;
}

.checkboxes label {
  font-weight: normal;
  margin-left: 5px;
  margin-right: 20px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.modal-body-container {
  background: var(--tabBgCol);
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 500px;
  padding: 40px;
  border-radius: 4px;
  height: auto;
  max-height: 700px;
  position: relative;

  .modal-content-wrapper {
    color: var(--primaryTextCol);
    h4 {
      font-size: 20px;
      margin-bottom: 8px;
      font-weight: 600;

      &.create-email-header {
        margin-bottom: 32px;
      }
    }

    & > .color-grey {
      margin-bottom: 22px;
    }
  }
}

.modalPage {
  padding-left: 0 !important;
  padding-top: 0;
  min-width: 100% !important;
  min-height: 100% !important;
  background: var(--primaryBgCol) !important;
  height: 100%;
  overflow: auto;
  border-radius: 0px;
}
.remove-padding-left {
  padding-left: 0;
}

.leftAlignItems {
  text-align: left !important;
}
.text-left {
  color: inherit;
}
.primaryBgColorClass {
  background-color: var(--primaryBgCol) !important;
}

.modal-note {
  color: var(--secondaryTextCol);
  font-size: 12px;
  line-height: 16px;
}

.password-shown,
.password-hidden {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  position: relative;
  bottom: 2px;
  margin: 0 8px;
  cursor: pointer;
}

.password-shown {
  background: url("../../assets/password-toggle-on.svg") no-repeat center /
    contain;
}

.password-hidden {
  background: url("../../assets/password-toggle-off.svg") no-repeat center /
    contain;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  border-bottom: 1px solid var(--readOnlyTxtBgCol);
  color: var(--modalHeadCol);
  justify-content: space-between;
}

.modal-footer {
  border-top: 1px solid var(--readOnlyTxtBgCol);
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
}

.btn-close {
  border: none;
  font-size: 28px;
  cursor: pointer;
  font-weight: bold;
  color: grey;
  background: transparent;
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
  line-height: 15px;
  padding: 0;
  background: none !important;

  &:focus {
    outline: 0;
    border: 0;
  }
}
.cancel-btn {
  position: static;
  font-size: 14px !important;
  margin-left: 24px;
  color: var(--primaryTextCol);
}

.success-banner {
  background: var(--tabBgCol);
  .header {
    margin-bottom: 22px;
    display: flex;
  }
  h5 {
    word-break: break-all;
  }

  img.block-image {
    margin-bottom: 13px;
  }

  .password-actions {
    position: relative;
    display: inline-block;
    user-select: none;

    .password-text {
      cursor: pointer;
    }

    .password-shown,
    .password-hidden {
      width: 15px;
      height: 15px;
    }

    p {
      color: var(--primaryTextCol);
      position: relative;
      margin-bottom: 4px;
    }
  }
  .send-details {
    width: 450px;
    word-break: break-word;
  }
}
.email-created {
  font-size: 20px;
  padding-left: 19px;
  word-break: break-word;
}
.email-creation-success-header {
  font-size: 14px;
}

.email-info-container {
  background: var(--tertiaryBgCol);
  border: 1px solid var(--lineSepaCol);
  border-radius: 2px;
  padding: 30px 24px;
  max-width: 500px;
  strong {
    padding-bottom: 18px;
    font-weight: 500;
    display: inline-block;
  }
  .span-link {
    text-decoration: underline;
    cursor: pointer;
    color: var(--tertiaryBtnCol);
  }
  .url,
  .email {
    margin-bottom: 9px;
    word-break: break-all;
  }
  .password {
    padding-bottom: 21px;
  }
}
.modal-page-success-banner {
  text-align: left;
  background-color: var(--primaryBgCol);
}
.margin-gap {
  height: 30px;
}
.doneBtn {
  padding: 10px 53px;
}
.infoEndBorder {
  border: 1px solid var(--lineSepaCol);
  margin-bottom: 29px;
}
.sendDetails {
  font-weight: 500;
  word-break: break-all;
}

.send-details {
  width: var(--inputBoxWidth);
  position: relative;

  .input {
    padding-right: 50px;

    &:focus {
      border: 1px solid var(--inputFocusBorderCol);
    }
  }
}

.addon-span {
  color: var(--primaryBtnCol);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
  cursor: pointer;

  &.invalid {
    opacity: 0.5;
    cursor: default;
  }
}

/*toggle switch css*/
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 14px;
  margin-right: 10px;
  top: 3px;
}
.marginFromLeft {
  margin-left: 5px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sliderBgCol);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: -3px;
  bottom: -1px;
  background-color: var(--tabBgCol);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 2px 6px 1px var(--boxShadowCol1);
}

input:checked + .slider {
  background-color: var(--primaryBtnCol);
}

/*input:focus + .slider {*/
/*box-shadow: 0 0 1px #2196F3;*/
/*}*/

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.email-error {
  display: flex;
  max-width: 430px;
}
</style>
