<template>
  <div v-if="!hasForgoPayments" class="stripe-payment-card">
    <div ref="card" v-show="!showSavedCard" id="card-element"></div>
    <div class="saved-card" v-if="showSavedCard">
      <span class="card-number"
        >XXXX XXXX XXXX {{ savedCard.lastFourDigits }}
      </span>
    </div>
    <div v-if="!hideUpdateCard" @click="showStripCardField" class="update-card">
      {{ $t("stripPaymentCard.updateCard") }}
    </div>
    <span
      class="error"
      v-html="cardValidationError"
      v-if="cardValidationError"
    />
    <p
      v-if="!skipPaymentCharge"
      v-html="helperText"
      :class="['helper-text', showCheckbox && 'amountText']"
    />
    <div class="checkbox" v-if="showCheckbox">
      <p v-if="helperSubText" v-html="helperSubText" class="sub-helper-text" />
      <input
        type="checkbox"
        id="checkboxContent"
        name="checkboxContent"
        value="checkboxContent"
        @change="toggleChecked"
        checked
        v-if="showAutoRenew"
      />
      <label v-if="showAutoRenew" for="checkboxContent">{{
        checkboxText
      }}</label>
    </div>
    <button
      :disabled="showButtonSpinner"
      :class="{
        'show-spinner': showButtonSpinner,
        'mt-16': skipPaymentCharge,
      }"
      @click="onPayNowClicked()"
      v-html="
        buttonText ||
        $t(
          skipPaymentCharge
            ? 'stripPaymentCard.payZero'
            : 'stripPaymentCard.payNow',
          { currencyCode }
        )
      "
    />
  </div>
  <div v-else class="stripe-payment-card">
    <div v-if="hasForgoPayments" class="forgo-payments-info">
      <div>
        {{
          $t("billingAndSubscription.paymentMethodWithPartner", [
            partnerCompanyName,
          ])
        }}
      </div>
    </div>
    <p v-html="forgoModeHelperText" class="helper-text" />
    <button
      :disabled="showButtonSpinner"
      :class="{ 'show-spinner': showButtonSpinner }"
      @click="onPayNowClicked()"
    >
      {{ buttonText || $t("stripPaymentCard.payNow") }}
    </button>
  </div>
</template>

<script>
import CountryNotSupportedModal from "@/components/CountryNotSupportedModal/CountryNotSupportedModal.vue";
import { COMMON_MODAL_EMITTERS } from "@/helpers/const";
import eventBus from "@/helpers/event-bus";
import { mapActions, mapGetters } from "vuex";
import { Stripe } from "@/helpers/stripe";
import {
  getCommonMedusaParamsForUpgradePlan,
  getProductToPurchaseAttr,
  triggerPurchaseAndBillingEvents,
} from "@/telemetry/medusaUtils";
import {
  getAppSource,
  getCurrencySymbol,
  getCurrentUserEmail,
  returnObjectIfValueExists,
} from "@/helpers";
import billingService from "@/services/billing/billing.service";
import Logger from "@/helpers/logger";
import bundleEvents from "@/telemetry/bundleEvents";

export default {
  name: "StripePaymentCard",
  data() {
    return {
      checked: true,
      showButtonSpinner: false,
      cardValidationError: null,
      domain: this.domainProp || smartStorage.getItem("domain"),
      loginEmail: this.loginEmailProp || smartStorage.getItem("loginEmail"),
      payment_transaction_id: null,
    };
  },
  props: {
    domainProp: { type: String },
    loginEmailProp: { type: String },
    isTrailPlan: {
      type: Boolean,
      default: false, // in existing flow this cmp used for purchase
    },
    amountToBePaid: {
      type: Number,
    },
    billingCycle: {
      type: String,
    },
    showAutoRenew: {
      type: Boolean,
      default: true,
    },
    hideUpdateCard: {
      type: Boolean,
    },
    skipPaymentCharge: {
      type: Boolean,
    },
    isProvisionFlow: {
      type: Boolean,
    },
    setAmountIncTax: {
      type: Function,
    },
    authorizationHeader: {
      type: String,
    },
    processPaymentAPI: {
      type: String,
    },
    processPaymentProps: {
      type: Object,
      required: false,
    },
    initPaymentAPI: {
      type: String,
    },
    showCheckbox: {
      type: Boolean,
    },
    checkboxText: {
      type: String,
    },
    helperText: {
      type: String,
      default: "",
    },
    forgoModeHelperText: {
      type: String,
      default: "",
    },
    helperSubText: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    paymentPayload: {
      type: Object,
      default: {},
    },
    currency: {
      type: String,
      default: "",
    },
    shouldStartPayment: {
      type: Function,
    },
    paymentEventsMedusaAttributes: {
      type: Object,
      default: () => {},
    },
    paymentFor: {
      type: String,
    },
    getMedusaAttr: {
      type: Function,
    },
    sourceHook: {
      type: String,
    },
    selectedRecord: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    newMailboxCount: {
      type: Number,
      require: false,
      default: () => -1,
    },
  },
  computed: {
    ...mapGetters([
      "savedCard",
      "locale",
      "partnerInfo",
      "paymentCardType",
      "isBlacklistedCountry",
    ]),
    showSavedCard() {
      return !!this.savedCard?.paymentMethodId;
    },
    hasForgoPayments() {
      return this.partnerInfo?.flags?.forgoPayments;
    },
    partnerCompanyName() {
      return this.partnerInfo?.companyName;
    },
    currencyCode() {
      return getCurrencySymbol(this.currency);
    },
  },
  methods: {
    ...mapActions({
      setCardType: "paymentCardType",
    }),
    toggleChecked() {
      this.$emit("toggleChecked");
    },
    showStripCardField() {
      this.$emit("openManageCardModal", "update", this.getMedusaAttr?.());
    },
    handleError(err) {
      this.showButtonSpinner = false;
      this.$emit("onPaymentFailed", err);
      console.log("transaction failed", err);
      const medusaPayload = {
        ...this.paymentEventsMedusaAttributes,
        source: getAppSource(),
        source_hook: this.sourceHook,
        error: err?.message,
        // add payment_card_type and payment_transaction_id key to payload only if it's value exist
        ...returnObjectIfValueExists("payment_card_type", this.paymentCardType),
        ...returnObjectIfValueExists(
          "payment_transaction_id",
          this.payment_transaction_id
        ),
      };
      triggerPurchaseAndBillingEvents.call(
        this,
        {
          ...medusaPayload,
          ...(this.selectedRecord
            ? getCommonMedusaParamsForUpgradePlan(this.selectedRecord)
            : {}),
          eventName: MEDUSA_EVENTS_MAP.PAYMENT_FAILED,
        },
        false
      );
      bundleEvents.logPaymentFailed({
        ...medusaPayload,
        ...getProductToPurchaseAttr(this.selectedRecord),
        ...this.getBundleEventAttr(),
      });
    },
    getBundleEventAttr() {
      return {
        payment_amount: this.amountToBePaid,
        new_billing_cycle: this.billingCycle,
        ...(this.newMailboxCount !== -1
          ? { new_mailbox_count: this.newMailboxCount }
          : {}),
      };
    },
    handleSuccess(res) {
      this.showButtonSpinner = false;
      this.showAddonPurchaseModal = true;
      this.cardValidationError = null;
      if (!this.hasForgoPayments) {
        this.cardElement.clear();
      }
      this.$emit("onPaymentSuccess", res);
      const medusaObject = {
        ...this.paymentEventsMedusaAttributes,
        source: getAppSource(),
        source_hook: this.sourceHook,
        // add payment_card_type and payment_transaction_id key to payload only if it's value exist
        ...returnObjectIfValueExists("payment_card_type", this.paymentCardType),
        ...returnObjectIfValueExists(
          "payment_transaction_id",
          this.payment_transaction_id
        ),
        ...(this.getMedusaAttr?.() || {}),
      };
      triggerPurchaseAndBillingEvents.call(this, {
        ...medusaObject,
        ...(this.selectedRecord
          ? getCommonMedusaParamsForUpgradePlan(this.selectedRecord)
          : {}),
        eventName: MEDUSA_EVENTS_MAP.PURCHASE_COMPLETED,
      });
      bundleEvents.logPurchaseComplete({
        ...medusaObject,
        ...getProductToPurchaseAttr(this.selectedRecord),
        ...this.getBundleEventAttr(),
      });

      triggerPurchaseAndBillingEvents.call(this, {
        ...medusaObject,
        eventName: MEDUSA_EVENTS_MAP.PAYMENT_COMPLETED,
      });
      bundleEvents.logPaymentComplete({
        ...medusaObject,
        ...getProductToPurchaseAttr(this.selectedRecord),
        ...this.getBundleEventAttr(),
      });
    },
    processPayment(trackingId) {
      this.http.postData(
        this.processPaymentAPI,
        {
          trackingId,
          domainName: this.domain,
          ...(this.processPaymentProps || {}),
        },
        this.$root.headers,
        this.handleSuccess, // success
        this.handleError // error
      );
    },
    // does the validation of card element and return stripe token if card is valid
    createStripeToken() {
      return this.stripe.createToken(this.cardElement);
    },
    /**
     * function to decide whether to initiate stripe payment or add card flow
     * @param {string} intent - stripe intent
     */
    addCardAndProcessPayment(intent) {
      if (this.isTrailPlan) {
        return this.confirmAddCardSetup(intent);
      }

      return this.confirmCardPayment(intent);
    },
    /**
     * function to add card and initiate the payment
     * @param {string} intent stripe intent
     */
    confirmCardPayment(intent) {
      return this.stripe.confirmCardPayment(intent, {
        payment_method: this.showSavedCard
          ? this.savedCard.paymentMethodId
          : {
              card: this.cardElement,
              billing_details: {
                email: this.loginEmail,
              },
            },
      });
    },
    /**
     * function to add card and initiate the payment
     * @param {string} intent stripe intent
     */
    confirmAddCardSetup(intent) {
      if (this.showSavedCard) {
        // incase of trial plan, skip add card process if card was already added
        return Promise.resolve({ error: false });
      }

      return this.stripe.confirmCardSetup(intent, {
        payment_method: {
          card: this.cardElement,
          billing_details: {
            email: this.loginEmail,
          },
        },
      });
    },
    async initPayment() {
      if (this.authorizationHeader) {
        this.$root.headers = {
          headers: { Authorization: this.authorizationHeader },
        };
      }

      return this.http.postData(
        this.initPaymentAPI,
        {
          ...this.paymentPayload,
          domainName: this.domain,
          currency: this.currency,
        },
        this.$root.headers,
        (data) => {
          // success
          if (data.chargeableAmount) {
            this.setAmountIncTax?.(data.chargeableAmount);
          }

          if (this.hasForgoPayments || this.skipPaymentCharge) {
            return this.processPayment(data.trackingId);
          }

          this.addCardAndProcessPayment(data.stripeSecret)
            .then((result) => {
              if (result.error) {
                const {
                  error: { payment_intent },
                } = result;
                if (payment_intent) {
                  this.payment_transaction_id = payment_intent.id;
                }
                return Promise.reject(result.error);
              }

              const { paymentIntent } = result;
              if (paymentIntent) {
                this.payment_transaction_id = paymentIntent.id;
              }

              this.processPayment(data.trackingId);
            })
            .catch(this.handleError);
        },
        this.handleError // error
      );
    },
    async saveCard() {
      try {
        const saveCardIntent = await billingService.getAddCardIntent();
        const data = await this.stripe.confirmCardSetup(
          saveCardIntent.stripeSecret,
          {
            payment_method: {
              card: this.cardElement,
              billing_details: {
                email: getCurrentUserEmail(),
              },
            },
          }
        );
        if (data.error) {
          return Promise.reject(data.error);
        }

        return Promise.resolve(data);
      } catch (e) {
        Logger.error("`saveCard` failed", e);
        return Promise.reject(e);
      }
    },
    // checked whether there are any errors in stripe card element
    hasValidationErrors() {
      return !this.showSavedCard && this.cardValidationError;
    },
    async validateCardAndInitiatePayment() {
      try {
        if (this.hasValidationErrors()) throw new Error("validation_error");

        this.showButtonSpinner = true;

        if (!this.showSavedCard) {
          const result = await this.createStripeToken();
          if (result.error) {
            this.showButtonSpinner = false;
            throw new Error("create_stripe_token_error");
          }

          const {
            token: { card },
          } = result;
          this.setCardType(card.funding);
          // handle medusa entity change on server when the payment card gets changed
          this.medusaEvents.entityChange({
            type: "titan_customer",
            attrs: {
              payment_card_type: card.funding,
            },
          });

          if (this.isProvisionFlow) {
            // incase of provision flow we have to save card before making req to neo/initPayment else API throws card not found
            // incase of billing flow billing/initPayment does take care of save card and payments
            await this.saveCard();
          }
        }

        await this.initPayment();
      } catch (e) {
        Logger.error("`validateCardAndInitiatePayment` failed", e);
        return false;
      }
    },
    sendPayNowClickedEvt() {
      const medusaPayload = {
        payment_for: this.paymentFor,
        source_hook: this.sourceHook,
        ...(this.getMedusaAttr?.() || {}),
      };
      triggerPurchaseAndBillingEvents.call(
        this,
        {
          eventName: MEDUSA_EVENTS_MAP.PAY_NOW_CLICKED,
          ...(this.selectedRecord
            ? getCommonMedusaParamsForUpgradePlan(this.selectedRecord)
            : {}),
          ...medusaPayload,
        },
        false
      );
      bundleEvents.logPayNowClicked({
        ...medusaPayload,
        ...getProductToPurchaseAttr(this.selectedRecord),
        ...this.getBundleEventAttr(),
      });
    },
    async onPayNowClicked() {
      try {
        if (this.isBlacklistedCountry) {
          // show modal
          eventBus.$emit(COMMON_MODAL_EMITTERS.OPEN_MODAL, {
            component: CountryNotSupportedModal,
            modalWidth: "470px",
            replaceCurrentModal: true,
          });
          return;
        }
        if (this.showButtonSpinner) {
          // already transaction in in-progress
          return false;
        }

        // inform medusa about pay now clicked event
        this.sendPayNowClickedEvt();

        if (this.hasForgoPayments) {
          // incase of godaddy/ForgoPayments we don't need to validate card/make any payment
          return this.startForgoPayments();
        }

        if (this.shouldStartPayment) {
          // this is CB function to do any action before starting payment
          // based on the success/failure resp we decide to start payments
          await this.shouldStartPayment();
        }

        return this.validateCardAndInitiatePayment();
      } catch (e) {
        Logger.error("`onPayNowClicked` failed", e);
        return Promise.reject(e);
      }
    },
    startForgoPayments() {
      this.showButtonSpinner = true;
      this.initPayment();
    },
    initStripeCardElement() {
      if (this.hasForgoPayments) return false;

      // eslint-disable-next-line no-undef
      this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
      /**
       * Send locale to stripe to get the translation for placeholder string of card element
       * Supported languages (https://stripe.com/docs/js/appendix/supported_locales)
       */
      this.cardElement = this.stripe
        .elements({ locale: this.locale })
        .create("card");
      this.cardElement.mount(this.$refs.card);
      this.cardElement.addEventListener("change", (event) => {
        if (event.error) {
          this.cardValidationError = event.error.message;
        } else {
          this.cardValidationError = null;
        }
      });
    },
  },
  mounted() {
    this.initStripeCardElement();
  },
};
</script>

<style lang="scss">
.stripe-payment-card {
  /* margin: 24px 0 0; */

  .helper-text {
    font-size: 14px;
    margin-top: 16px;

    b {
      font-size: 16px !important;
    }
  }

  #card-element {
    width: 500px;
    padding: 10px 5px;
    border-radius: 4px;
    border: solid 1px var(--disableCol);
    background-color: var(--tabBgCol);
  }

  .error {
    height: 15px;
    display: block;
    margin: 2px 0 4px;
  }
  .amountText {
    margin-bottom: 0;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--lineSepaCol);
  }
  .checkbox {
    // $params are in the given order
    // margin-top , margin-right, margin-bottom, margin-left
    // padding-top , padding-right, padding-bottom, padding-left
    // width, height
    @include custom-checkbox(17, 12, 39, 0, 2, 2, 2, 2, 16, 16);
  }

  .hide {
    display: none;
  }

  button {
    padding: 10px 18px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: var(--primaryBtnCol);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    text-align: center;
    color: var(--tabBgCol);
    outline: none !important;
    border: 1px solid var(--primaryBtnCol);

    &:active {
      border: 1px solid var(--primaryBtnCol);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &.show-spinner {
      @include button-loader();
    }
  }

  .sub-helper-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin: 17px 0 29px;
  }

  .saved-card {
    width: 100%;
    //max-width: 360px;
    padding: 14px 16px 14px 50px;
    border-radius: 4px;
    border: solid 1px var(--lineSepaCol);
    background-color: var(--readOnlyTxtBgCol);
    font-size: 14px;
    line-height: 1.43;
    color: var(--primaryTextCol);
    background-image: url("../../assets/cardIcon.svg");
    background-repeat: no-repeat;
    background-position: top 15px left 16px;
  }

  .update-card {
    margin: 16px 0 0;
    font-weight: 500;
    text-decoration: underline;
    color: var(--linkBtnCol);
    cursor: pointer;
  }

  .forgo-payments-info {
    display: inline-block;
    padding: 9px 10px;
    border-radius: 4px;
    border: 1px solid var(--lineSepaCol);
    background: var(--forgoPaymentsInfoBg);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    & + p {
      margin: 22px 0 24px;
    }
  }

  .forgo-payments-info {
    display: inline-block;
    padding: 9px 10px;
    border-radius: 4px;
    border: 1px solid var(--lineSepaCol);
    background: var(--forgoPaymentsInfoBg);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    & + p {
      margin: 22px 0 24px;
    }
  }
}
</style>
